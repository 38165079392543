import { HttpClient as AngularHttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core'
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {  
  private _tokenId = null;
  private _pov = null;
  userName = null;
  subscriberName = null;
  CanAdd: boolean;
  httpClient: AngularHttpClient;
  
  getSubscriberName(){
    return this.subscriberName;
  }
  getUser(){
    if(this._pov){
      return this.userName;
    }
    return null;
  }
  setTokenId(token) {
    this._tokenId = token;
  }

  getTokenId(): any {
    return this._tokenId;
  }

  setPOV(pov: String) {
    this._pov = pov;
    
    var e = new CustomEvent('povchanged', { detail: pov });

    window.dispatchEvent(e);
  }

  getPOV(): any {
    return this._pov;
  }

  isAuthenticated() {
    if(this._tokenId != null)
      return true;
    
    // check stored session
    const token = localStorage.getItem( 'authToken' );
    const pov = localStorage.getItem( 'authPov' );    
    if (token && pov) {
      this.setTokenId(token);
      this.setPOV(pov);
      this.userName = localStorage.getItem( 'userName' );
      this.subscriberName = localStorage.getItem( 'subscriberName' );
      return true;
    }

    // not logged in
    return false;
  }

  logOut() {
    this._tokenId = null;
    this._pov = null;
    localStorage.removeItem( 'authToken' );
    localStorage.removeItem( 'authPov' );
    localStorage.removeItem( 'userName' );
    localStorage.removeItem( 'subscriberName' );
  }

  constructor(public http: AngularHttpClient) {
    this.httpClient = http
  }
}
