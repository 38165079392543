import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Mediator } from '../core-services/mediator/market-utilities.mediator';

@Component( {
    selector: 'structure-element',
    templateUrl: './structure-element.component.html',
    styleUrls: [ './structure-element.component.scss' ]
} )

export class StructureElementComponent
{
  constructor (private mediator:Mediator)
  {
    const addColor = (name: string, value: string) => {
      this.colors.push({ name, value });
    }
    addColor('Blumine', '#1E5589');
    addColor('Torch Red', '#FF0C3E');
    addColor('Limeade', '#33B700');
    addColor('Orange Peel', '#FFA000');
    
  }

  getRandomName(){
  }
  addPerspective(){

  }
  actionByType( item: any ){
    switch( item.type ){
      case 'addClient':
        console.log('add client...');
        break;
      case 'addPartner':
        console.log('add partner...');
        break;
      case 'permission':
        this.permissionFor.emit();
        break;
      case 'remove':
        this.remove.emit();
        break;
    }
  }
  newItem( actionType: string ){
    switch( actionType ){
      case 'addClient':
      case 'addCustomer':
        this.newOrganization.emit();
      break;
      case 'addPartner':
        this.newPartner.emit();
      break;
    }
  }
  actionByTypeFor( item: any, actionType: string ){
    let type = '';
    switch( actionType ){
      case 'addClient':
        type = 'Client';
        break;
      case 'addCustomer':
        type = 'Customer';
      break;
      case 'addPartner':
        this.addAsPartner.emit( item );
        return;
    }
    this.addChildAs.emit({ item, type });
  }
  toggleInfo( el: any ){
    if( this.permissions.viewDisabled ) return;
    el.expanded = !el.expanded;
    if(el.expanded){
this.mediator.GetACLbyAccesor('B350A77F-2CB6-4A26-BFE1-32F2BBC46F13').subscribe((x)=> console.log(x))
    }
  }
  useCustomMenu( type: string ){
    switch (type){
      case 'addPartner':
      case 'addClient':
      case 'addCustomer':
        return true;
    }
    return false;
  }

  ngOnInit(){
    this.menuItems = [
      { name: 'Remove', type: 'remove' }
    ];
    switch(this.type){
      case 'subscriber':
        Array.prototype.push.apply(this.menuItems, [
          { name: 'Add client', type: 'addClient' },
          { name: 'Add partner', type: 'addPartner' },
        ]);
        break;
      case 'client':
      case 'customer':
        Array.prototype.push.apply(this.menuItems, [
          { name: 'Permissions', type: 'permission' },
          { name: 'Add customer', type: 'addCustomer' }
        ]);
        break;
    }
  }
  items: any = {};
  @Input() title: string;
  @Input() subtitle: string;
  @Input() color: string;
  @Input() isTreeItem: boolean;
  @Input() useDendogramDot: boolean;
  @Input() useTreeDot: boolean;
  @Input() isTreeFirstChild: boolean;
  @Input() isTreeLastChild: boolean;
  @Input() isDemogramOnlyChild: boolean;
  @Input() onlyChild: boolean;
  @Input() lastChild: boolean;
  @Input() firstPartner: boolean;

  @Input() permissions: any = {
    hide: false,
    manageDisabled: false,
    viewDisabled: false
  };

  @Input() type: string;

  @Input() availableOrganizations: any[];
  @Input() availablePartners: any[];

  menuItems: any[] = [];
  colors: any[] = [];

  @Output() permissionFor = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() addChildAs = new EventEmitter<{ item: any, type: string }>();
  @Output() addAsPartner = new EventEmitter<any>();
  @Output() changeSubtitle = new EventEmitter();
  @Output() changeColor = new EventEmitter();
  @Output() editBranch = new EventEmitter();

  @Output() newOrganization = new EventEmitter();
  @Output() newPartner = new EventEmitter();
}