import { Component, Input, Output, EventEmitter, QueryList, ViewChildren } from '@angular/core';
import { Organization } from '@smartobjx/smart.objx.models';

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatInput } from '@angular/material/input';

@Component( {
    selector: 'perspective-list',
    templateUrl: './perspective-list.component.html',
    styleUrls: [ './perspective-list.component.scss' ]
} )

export class PerspectiveListComponent
{
  @ViewChildren("filterInput") textInput: QueryList<MatInput>;
  draggingItem
  doFilter(){
    this.filter.emit( { included: this.filterIncluded, notIncluded: this.filterNotIncluded } );
  }
  onDragEnd( event: any ){
    console.log('onDragEnd');
    this.draggingItemChange.emit( null );
  }
  onDragStart( event: any, item: any ) {
    console.log('onDragStart');
    const newItem = {
      Color: "#5550b3",
      Organization: item,
      Partners: [],
      Tag: "New",
      Units: []
    };
    this.draggingItemChange.emit( newItem );
  }
  
  cdkDragStarted( event: any, item: any ){
    this.dragged = item;
  }
  cdkDragReleased( event: any ){
    this.dragged = undefined;
  }
  dragged: any;
  isDragged( item: any ){
    // if(this.dragged){
    //   console.log(this.dragged.Name === item.Name ? '+' : '-', this.dragged.Name, item.Name);
    // }
    return this.dragged && this.dragged.Name === item.Name;
  }
  getNames(item: any, dragged: any){
    return  `${!!dragged} | ${this.dragged ? this.dragged.Name : 'none'} - ${item.Name}`;
  }

  //#region methods
  filterList( e: any ) {
    let value = e.target.value;
    if( value ){
      value = value.trim().toLowerCase();
      this.extraListFiltered = this.extraList.filter(o => !!~o.Name.toLowerCase().indexOf(value));
    } else {
      this.extraListFiltered = this.extraList.slice();
    }
  }
  closeMenu(){
    setTimeout(() => {
      this.textInput.toArray().forEach((o: any) => {
        const el = o._elementRef.nativeElement;
        el.value = "";
      });
      this.extraListFiltered = this.extraList.slice();
    }, 100);
  }
  //#endregion
  
  //#region contruction
  constructor (){
  }
  
  ngOnChanges( changes: any ){
    if( changes.items && changes.items.currentValue && changes.items.currentValue.length){
        // this.items.organizations = changes.organizations.currentValue;
      // console.log(changes.items);
      this.itemsAsPerspectiveElements = changes.items.currentValue.map(o => {
        return {
            Organization: o,
            Tag: 'new',
            Units: []
        }
      });
    }
    if(
        changes.extraList && changes.extraList.currentValue && changes.extraList.currentValue.length != null
        && (
          changes.extraList.previousValue == null // no previous data
          || changes.extraList.currentValue.length != changes.extraList.previousValue.length // different sizes
        )
      ){
      this.extraListFiltered = changes.extraList.currentValue.slice();
    }
  }
  toggleList(){
    this.collapsed = !this.collapsed;
  }
  isNotInExtraList( item: any ) {
    return !this.extraList.length
      || !this.extraList.filter(o => o.OID === item.OID ).length;
  }
  isNotInExtraLists( item: any ) {
    return !this.extraLists.length
      || !this.extraLists.filter(l => l.filter( o => o.OID === item.OID ).length > 0 ).length;
  }
  //#endregion

  //#region 
  @Input() type: string;
  @Input() items: any[] = [];
  @Input() itemsOut: any[] = [];
  @Input() itemsAsPerspectiveElements: any[] = [];
  @Input() disableOptions: boolean;
  @Input() extraList: any[] = [];
  @Input() extraLists: any[] = [];
  extraListFiltered: any[] = [];

  collapsed: boolean = false;
  @Input() isLoading: boolean = true;
  @Input() asPartner: boolean;
  
  // @Output() sort = new EventEmitter();
  
  i_filterIncluded: boolean = false;
  i_filterNotIncluded: boolean = false;

  get filterIncluded(): boolean {
    return this.i_filterIncluded;
  }
  set filterIncluded( value ) {
    this.i_filterIncluded = value;
    this.doFilter();
  }
  get filterNotIncluded(): boolean {
    return this.i_filterNotIncluded;
  }
  set filterNotIncluded( value ) {
    this.i_filterNotIncluded = value;
    this.doFilter();
  }
  
  @Output() filter = new EventEmitter<{ included: boolean, notIncluded: boolean }>();
  @Input() disableFilter: boolean = false;
  @Input() filterTitleCode: string;
  get filterTitle(): string {
    const code = this.filterTitleCode;
    return 'Show ' + (code === 'all' ? 'not added'
      : code === 'notadded' ? 'added'
      : 'all');
  } 
  
  @Output() add = new EventEmitter();
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() addChild = new EventEmitter<{ item: Organization, parent: Organization }>();
  @Output() removeItem = new EventEmitter<any>();
  
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }
  //#endregion

  @Output() draggingItemChange = new EventEmitter<any>();
}