import { Component, Output, EventEmitter, Input, ViewChildren, QueryList } from '@angular/core';
// import Mediator from '../core-services/mediator/fixes.mediator';
import { Title } from '@angular/platform-browser';
import { AccessControlEntry, Organization } from '@smartobjx/smart.objx.models';
import { Tools } from '../shared/Tools';
import Mediator from '../core-services/mediator/structures-adm.mediator';
import * as _ from "lodash";
import { ReadVarExpr } from '@angular/compiler';
import { Mediator as MediatorMarket } from '../core-services/mediator/market-utilities.mediator';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'permission-drawer',
    templateUrl: './permission-drawer.component.html',
    styleUrls: ['./permission-drawer.component.scss']
})

export class PermissionDrawerComponent {
    aclList: any[];
    @Input() items: any = {};
    @Input() organization: any = {};
    @Output() close = new EventEmitter();
    appliedFilter: string = "All items";
    currentSearch: string;
    searchChips: string[] = [];
    partners: any[];
    loading: boolean;
    get searchList(): string[] {
        const list = this.searchChips.slice();
        if (this.currentSearch) {
            list.push(this.currentSearch.toLowerCase());
        }
        return list;
    }

    constructor(
        private mediatorMarket: MediatorMarket,
        private mediator: Mediator,
        public _dialog: MatDialog,
        title: Title,
    ) {
        console.log("test deploy")
    }

    searchPreview(e: any, el: any) {
        if (e.key === 'Enter') {
            this.search(el);
        }

    }
    search(el: any, clean: boolean = true) {
        const value = el.value.trim();
        if (value && Tools.isNotInArray(value, this.searchChips))
            this.searchChips.push(value.toLowerCase());
        if (clean) el.value = '';
        this.selectFilter()
    }
    removeChip(value: string) {
        const i = this.searchChips.indexOf(value);
        this.searchChips.splice(i, 1);
        this.selectFilter()
    }
    clearSearch() {
        this.searchChips = [];
        this.currentSearch = '';
        this.selectFilter('All items')
    }
    save() {
     //  this.mediatorMarket.UpdateACLEntries(this.aclList).subscribe(x => {
     //      this._dialog.open(SimpleDialogComponent, {
     //          panelClass: 'smart-objx',
     //          autoFocus: true,
     //          data: {
     //              title: 'Permissions ',
     //              hideButton2: true,
     //              content: 'Permissions updated successfully',
     //              button1Text: 'Close',
     //              button1Color: 'primary',
     //          }
     //      }).afterClosed().subscribe(() => {
     //          this.close.emit()
     //      })

     //  })


    }
    changePermision(item) {
        var acl = new AccessControlEntry();
        acl = Object.assign({}, item);
        this.loading = true;

        this.mediatorMarket.SaveACLEntry(acl).subscribe(x => {
            this.loading = false;
            console.log("success", x)
        }, error => {
            item.Access = !item.Access
            this.loading = false;
        })


    }
    public reloadOrganizations(organizations) {
        this.aclList = organizations

        this.selectFilter()
    }

    selectFilter(filter = null) {
        this.appliedFilter = _.isNil(filter) ? this.appliedFilter : filter
        switch (this.appliedFilter) {
            case 'All items':
                this.aclList = _.map(this.aclList, item => { return { ...item, Show: true } })
                break;
            case 'Only selected items':
                this.aclList = _.map(this.aclList, item => { return { ...item, Show: item.Access } })

                break;
            case 'Only unselected items':
                this.aclList = _.map(this.aclList, item => { return { ...item, Show: !item.Access } })

                break;
            default:
                break;
        }
        if (this.searchChips.length > 0) {
            this.aclList = _.map(this.aclList, item => {
                return { ...item, Show: this.findNames(item) }
            })
        }
    }

    findNames(item) {
        return item.Show && this.searchChips.some(chip => item.Accessee.Name.toLowerCase().includes(chip.toLowerCase()));
    }


}
