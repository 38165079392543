import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Tools } from '../shared/Tools';
import { NativeDateAdapter, MatDateFormats, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';

// #region Custom Format
const currentFormat: string = 'MM / DD / YYYY';
const dateTo = {
    string(date: Date) {
        let day: string = Tools.padZero(date.getDate().toString());
        let month: string = Tools.padZero((date.getMonth() + 1).toString());
        let year = date.getFullYear();

        return `${month} / ${day} / ${year}`;
    }
}
export class AppDateAdapter extends NativeDateAdapter {
    format(date: Date, displayFormat: Object): string {
        if (displayFormat === currentFormat) {
            return dateTo.string(date);
        }
        return date.toDateString();
    }
}

const APP_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: currentFormat,
    },
    display: {
        dateInput: currentFormat,
        monthYearLabel: { year: 'numeric', month: 'numeric' },
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' },
    }
};
// #endregion

@Component( {
    selector: 'custom-datepicker',
    templateUrl: './custom-datepicker.component.html',
    styleUrls: [ './custom-datepicker.component.scss' ],
    providers: [ // Custom Format
        {provide: DateAdapter, useClass: AppDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
    ]
} )
export class CustomDatepickerComponent
{
    // #region Event Handlers
    compareObjects(o1: any, o2: any): boolean {
        return o1.getTime() === o2.getTime();
    }
    dateClass = (d: Date) => {
        let days = this.getVersionsForDay(d);

        return days.length === 1 ? 'date-with-version'  // light green
            : days.length > 1    ? 'date-with-versions' // green
            : undefined;
    }
    checkVersion( button: MatMenuTrigger ) {
        button.closeMenu();
        let date = this.mergeDateAndTime(this.versionDate, this.versionTime);
        this.selectedDate = date;
        this.onChange.emit(date); // fix no binding
        let days = this.getVersionsForDay(date);
        if(days.length == 0) {
            let prevDate = this.versionDates.reduce((r, o) => {
                return o.getTime() > r.getTime()                // greater than previous
                    && o.getTime() < date.getTime() ? o : r;    // but lower than input date
            });
            this.selectedVersion = prevDate;
        } else {
            this.selectedVersion = new Date(date.getTime());
        }
    }
    openDatepicker( button: MatMenuTrigger ) {
        this.openVersions.emit(this.ruleId);
        this.updateMenuValues();
    }
    setToday(){
        this.versionDateForm.setValue(new Date());
    }
    isNotToday(date: any): boolean {
        return date.value !== dateTo.string(this.today);
    }
    get today(): Date {
        return new Date();
    }
    // #endregion
    
    // #region Private Methods
    private updateMenuValues(){
        let date = this.selectedDate;
        this.versionDate = new Date(date.getTime());
        this.versionTime = new Date(date.getTime());
        this.versionDateForm.setValue(this.versionTime);
    }
    private getVersionsForDay(d: Date) : Date[]{
        return this.versionDates.filter(o =>
            o.getFullYear() === d.getFullYear()
            && o.getMonth() === d.getMonth()
            && o.getDate() === d.getDate()
        );
    }
    private mergeDateAndTime(date: Date, time: Date) : Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());
    }
    private checkHours(date: Date) {
        this.availableHours = this.getVersionsForDay(date);
    }
    private updateTime(date: Date){
        let dates = this.getVersionsForDay(date);
        if(dates.length){
            const i = dates.map(o => o.getTime()).indexOf(date.getTime())
            date = this.versionDate = !!~i ? dates[i] : dates[dates.length - 1];
        } else {
            date = this.versionDate = date; // sets last if exists any record or just set the selected date (from input)
        }
        this.versionTime = new Date(date.getTime());
        this.checkHours(date);
    }
    // #endregion

    // #region Construction & Finalization
    constructor ( private _snackBar: MatSnackBar ) 
    {
        this.versionDateForm = new UntypedFormControl(null, { updateOn: 'blur' });
        this.versionDateForm.statusChanges.subscribe(status => {
            let date = this.versionDateForm.value;
            if(date){
                if (status === 'INVALID' && date.getTime() < this.minDate.getTime()) {
                    this._snackBar.open(`The rule was created at : ${dateTo.string(this.minDate)}`, null, {
                        duration: 3000,
                        verticalPosition: 'top',
                        horizontalPosition: 'end',
                        panelClass: 'info-warn'
                    });
                }
                this.updateTime( date );
            }
        });
    }
    ngOnChanges( changes: any ){
        if(changes.versionDates && !changes.versionDates.firstChange && changes.versionDates.currentValue.length){
            let date = this.versionDateForm.value;
            this.updateTime( date );
        }
    }
    // #endregion

    // #region Properties
    get selectedDate(): Date
    {
        return this.i_SelectedDate;
    }
    @Input()
    set selectedDate( date: Date )
    {
        if(date){
            this.i_SelectedDate = date;
            this.updateMenuValues();
        }
    }
    @Input() initialDate: Date;
    @Input() versionDates: Date[] = [];
    @Input() text: string;
    @Input() color: string;
    @Input() minDate: Date;
    @Input() ruleId: string;
    @Input() isLoading: boolean;
    // #endregion

    // #region Data Elements
    private i_SelectedDate: Date;
    private selectedVersion: Date;
    private versionDate: Date;
    private versionTime: Date;
    private availableHours: Date[] = [];

    versionDateForm: UntypedFormControl;
    // #endregion
    
    // #region Event Emitters
    @Output() onChange = new EventEmitter<Date>();
    @Output() onDelete = new EventEmitter<any>();
    @Output() onEditName = new EventEmitter<any>();
    @Output() onDebug = new EventEmitter<any>();
    @Output() openVersions = new EventEmitter<string>();
    // #endregion
}