export const environment = {
  production: false,
  settingsServiceBasePath: 'https://api.dev.smartobjx.com/settings-dev',
  accessServiceBasePath:'https://access.dev.smartobjx.com/api',
  structureServiceBasePath: 'https://api.dev.smartobjx.com/structures-dev',
  marketServiceBasePath: 'https://market.dev.smartobjx.com',//https://market.dev.smartobjx.com/api/
  publicBasePath: 'https://api.dev.smartobjx.com/public',
  SubcriptionService:'https://so-preproduction-subscriptionmanagement-east.azurewebsites.net/',


  // structureServiceBasePath: 'https://smartobjxpreprodgateway.azure-api.net/smartStructures',
  clientAPITest: 'http://localhost/NomadApp/api/Mockup/location/structure'//'https://nomadapp-as-preprod-ussc.azurewebsites.net/api/api/Mockup/location/structure'
};
