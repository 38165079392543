<button mat-icon-button class="overline"
    [color]="color"
    [matMenuTriggerFor]="versions"
    #menuTrigger="matMenuTrigger"
    (click)="openDatepicker( menuTrigger )"
    matTooltip="Versions"
>
    <mat-icon style="color: white;">history</mat-icon>
    <span *ngIf="text" style="margin-left: 5px;">{{ text }}</span>
</button>
<mat-menu #versions [overlapTrigger]="false" class="datepicker">
    <div class="custom-container-versions" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()">
        <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading" style="position: relative;"></mat-progress-bar>
        <ng-container *ngIf="!isLoading">
            <div class="info">Please, pick a date to see a version</div>
            <mat-form-field #mainInput>
                <mat-label>Select date</mat-label>
                <input matInput #versionInput
                    placeholder="mm / dd / yyyy"
                    autocomplete="off"
                    [formControl]="versionDateForm"
                    [matDatepicker]="version"
                    [min]="minDate" required
                />
                <mat-datepicker #version [dateClass]="dateClass" ></mat-datepicker>
                <mat-icon matSuffix (click)="version.open()">calendar_today</mat-icon>
                <mat-hint>Month, day, year</mat-hint>
            </mat-form-field>
            
            <mat-form-field *ngIf="availableHours.length > 1">
                <mat-label>Select time</mat-label>
                <mat-select [(ngModel)]="versionTime" [compareWith]="compareObjects">
                    <mat-option *ngFor="let hour of availableHours" [value]="hour">
                        {{ hour | date: 'HH : mm' }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-card-actions fxFlexAlign="end" align="end" style="position: relative;" [class.actions-fix]="isNotToday(versionInput) && !(availableHours.length > 1)">
                <button *ngIf="isNotToday(versionInput)" mat-raised-button color="primary" class="today" (click)="setToday()">Today</button>
                <button mat-button class="link-button" (click)="menuTrigger.closeMenu()">Close</button>
                <button mat-button class="link-button" (click)="checkVersion( menuTrigger )" [disabled]="!versionDateForm.valid" >Ok</button>
            </mat-card-actions>
        </ng-container>
    </div>
</mat-menu>