import { AccessServiceConfiguration, StructuresServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './authentication/auth.service';
import { environment } from '../../environments/environment';

export function accessConfigurationService() {
  return (_authenticationService: AuthService): AccessServiceConfiguration => {
    return new AccessServiceConfiguration({
      httpClient: _authenticationService.http,
      basePath: environment.accessServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}