<mat-drawer-container class="simple-layout" [hasBackdrop]="true">
  <mat-drawer-content cdkDropListGroup>
    <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

    <mat-drawer-container class="inner-layout" [hasBackdrop]="false" [autosize]="true">
      <mat-drawer mode="side" [opened]="validateTab([1])" *ngIf="drawerState" class="appDrawer">
        <application-browser [ngStyle]="{'display': validateTab([0]) ? 'block': 'none',
        'padding-right': validateTab([2]) ? '12px': 'unset'}" style="padding-top:10px;padding-left: 8px;" class="md-card-full" (closeBrowser)="closeBrowser($event)"
          (selectApplicationEvent)="selectApplication($event)"></application-browser>
        <ng-container *ngIf="validateTab([2])">
          <application-editor class="md-card-full" [model]="this.model" style="padding-top:10px"
            [newApplication]="this.newApplication" (close)="closeApplication($event)"
            (openrole)="openRole($event)"  (onDeleteAllRelationship)="DeleteAllRelationship($event)" (onDeleteNode)="DeleteNode($event)"></application-editor>
        </ng-container>

      </mat-drawer>

      <mat-drawer mode="side" [opened]="validateTab([3]) " *ngIf="validateTab([3])" [position]="'end'"
        class="appDrawer">
        <role-editor class="md-card-full" [model]="this.model" style="margin-right: 12px;padding-top:10px"
          [roleModel]="this.roleSelected" [newRole]="this.newRole"
          (closeEntitlement)="openEntitlement=false;drawerState = [0,1,2];refreshApplication($event)"
          (refreshApplication)="refreshApplication($event)" (closeRole)="closeRole($event)"
          (openEntitlement)="openEntitlementTab($event)"></role-editor>
        <entitlement-editor style="padding-top:10px;padding-bottom:17px ;" *ngIf="openEntitlement"
          (closeEntitlement)="closetabEntitlement($event)"></entitlement-editor>
      </mat-drawer>
      <mat-drawer-content class="main-drawer-content">

        <ng-template #drawerContent>
          <!--   <pre>{{this.model | json}}</pre>-->
          <ng-container *ngIf="this.model">
            <role-structure [application]="this.model" (openrole)="openRole($event)"
              (refreshApplication)="refreshApplication($event)"></role-structure></ng-container>
        </ng-template>


        <ng-container *ngTemplateOutlet="drawerContent"></ng-container>
      </mat-drawer-content>

      <!-- placeholder for the first element -->
      <mat-drawer-content class="main-drawer-content" *ngIf="selectedPerspective && selectedPerspective.Tree == null"
        cdkDropList (cdkDropListDropped)="addChild( $event.item.data.item )">
      </mat-drawer-content>

    </mat-drawer-container>
  </mat-drawer-content>

  <mat-drawer mode="over" [(opened)]="showPermissions" position="end">
    <permission-drawer [organization]="selectedOrganization" [items]="customersForMarket"
      (close)="showPermissions=false;"></permission-drawer>
  </mat-drawer>
</mat-drawer-container>


<ng-template #perspectiveConfig>
  <div class="h6" matDialogTitle>{{ this.selectedPerspective.Name }} configurations</div>
  <mat-dialog-content>
    <mat-form-field class="disabled-but-looks-enabled" style="width: 600px;">
      <input matInput placeholder="Client API" [(ngModel)]="this.editingClientAPI" />
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>close</button>
    <button mat-raised-button matDialogClose color="primary" (click)="setClientAPI()">Set</button>
  </mat-dialog-actions>
</ng-template>