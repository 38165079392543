import { CustomValidator } from "./Validation";
import { RuleSet, Rule, CalculationRule } from "@smartobjx/smart.objx.models";

export class Tools {
    static getDaysInMonth(m: number, y: number): number {
        return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
    }
    static createCopy(data: any) {
        let rule;
        if( CustomValidator.is(data, 'ruleset') ){
            rule = new RuleSet(data);
        } else if( CustomValidator.is(data, 'rule') ){
            rule = new Rule(data);
        } else if( CustomValidator.is(data, 'calculationrule') ){
            rule = new CalculationRule(data);
        }
        return rule;
    }
    static getType(data: any){
        let type;
        if( CustomValidator.is(data, 'ruleset') ){
            type = 'ruleset';
        } else if( CustomValidator.is(data, 'rule') ){
            type = 'rule';
        } else if( CustomValidator.is(data, 'calculationrule') ){
            type = 'calculationrule';
        } else if( CustomValidator.is(data, 'callbackrule') ){
            type = 'callbackrule';
        }
        return type;
    }
    static padZero(n: string, len: number = 2) : string {
        return ('0'.repeat(len) + n).slice(-len);
    }
    static dateToURLString(date: Date, fix: boolean = true) : string {
        if( typeof date === 'string' ) return date;
		let day: string = this.padZero(date.getUTCDate().toString());
		let month: string = this.padZero((date.getUTCMonth() + 1).toString());
		let year = date.getUTCFullYear();
		let hour: string = this.padZero(date.getUTCHours().toString());
		let minutes: string = this.padZero(date.getUTCMinutes().toString());
		let seconds: string = fix ? '59' : this.padZero(date.getUTCSeconds().toString());
		let milliseconds: string = fix ? '999' : this.padZero(date.getUTCMilliseconds().toString(), 3);

		return encodeURIComponent(`${year}-${month}-${day}T${hour}:${minutes}:${seconds}.${milliseconds}`);
    }
    static dateToURLStringAsDate(date: Date, fix: boolean = true) : Date {
        // @ts-ignore: Force cast string to Date - unknown is not available at TS2
        return this.dateToURLString(date, fix) as unknown as Date;
    }
    static isEmptyGuid(oid: string){
        if(oid){
            return oid === "00000000-0000-0000-0000-000000000000";
        }
        return true;
    }
    static getIconByType( record: any ){
        switch( CustomValidator.getType( record ) ){
            case 'ruleset': return 'file_copy';
            case 'rule': return 'insert_drive_file';
            case 'calculationrule': return 'exposure';
            case 'callbackrule': return 'link';
        }
    }
    static dateToString( date: Date ) {
        return `${date.toLocaleDateString('en-GB')} ${date.toLocaleTimeString('en-GB')}.${('0'.repeat(3) + date.getMilliseconds()).slice(-3)}`;
    } 

    static toShortDate( d: Date ){
        const date = CustomValidator.ensureDate( d );
		let day: string = this.padZero(date.getUTCDate().toString());
		let month: string = this.padZero((date.getUTCMonth() + 1).toString());
		let year = date.getUTCFullYear();
        return `${month}/${day}/${year}`;
    }
    static isInArray( value: any, array: any){
        return !this.isNotInArray( value, array );
    }
    static isNotInArray( value: any, array: any){
        return !~array.indexOf(value)
    }


    static generateRandomLetter() {
        var randomNumber = Math.floor(Math.random() * 26);
        var randomLetter = String.fromCharCode(97 + randomNumber);
        return randomLetter;
      }
      
      static generateRandomNumber() {
        var randomNumber = Math.floor(Math.random() * 999);
        return randomNumber.toString();
      }
      
      static generateLinkID() {
        var randomLetter = Tools.generateRandomLetter();
        var randomNumber = Tools.generateRandomNumber();
        return randomLetter + randomNumber;
      }          
}
