<mat-card appearance="outlined" class="full-height mat-main-card access" [class.mat-card-raised]="isActive">


    <mat-card-title style="border-bottom: unset; min-width: 250px;">
        <span class="primary">Applications</span>
        <div></div>

    </mat-card-title>
    <mat-card-content *ngIf="useCasesError">
        <div class="h4">Something went wrong...</div>
        <span>{{ useCasesError }}</span>
    </mat-card-content>

    <!-- on empty list -->
    <mat-card-content>
        <mat-form-field class="search-bar" *ngIf="!isEmpty && model">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput [(ngModel)]="currentSearch" placeholder="Search"
                (keyup)="searchPreview( $event, searchInput )" #searchInput />

        </mat-form-field>
    </mat-card-content>

    <mat-card-content *ngIf="isEmpty">
        <div style="text-align: center;">
            <mat-icon svgIcon="use_cases_empty" class="use-cases-empty"></mat-icon>
        </div>
        <div class="h4">This section is empty</div>
        <div class="h6">Start creating applications</div>
    </mat-card-content>

    <mat-card-content class="main-content" style="padding: 0;">
        <span>This window will show all the current applications.</span>
        <span style="color: blue;text-decoration: underline;text-align: right;cursor: pointer;" (click)="openUploadManifest()">Import Manifest</span>
        <mat-card-content> <!--style="padding: 0;"-->
            <mat-list *ngIf="showFilterMessage">
                <mat-list-item class="title">
                    <span class="text">{{ showFilterMessage==='active' ? 'ACTIVE': 'DISABLED' }} USE CASES</span>
                    <button mat-icon-button (click)="this.filterUseCases('all'); showFilterMessage=null;"
                        matTooltip="Remove filter">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>

            <div *ngIf="searchChips.length">
                <mat-chip-listbox #chipList>
                    <span>Search results for:&nbsp;</span>
                    <mat-chip-option *ngFor="let chip of searchChips" (removed)="removeChip(chip)" (click)="removeChip(chip)">
                        {{ chip }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip-option>
                </mat-chip-listbox>
            </div>
            <div *ngIf="applicationsFull?.length && applicationsFiltered?.length === 0" class="no-results-filter">
                <div style="text-align: center;">
                    <mat-icon svgIcon="dead_end" class="dead-end"></mat-icon>
                </div>
                <div class="h4">No matching applications found.</div>
                <div class="h6">Please make sure your keywords are spelled correctly.</div>
                <a (click)="clearSearch()" color="primary" class="link">Reset search</a>
            </div>
            <ng-container *ngIf="applicationsFiltered && applicationsFiltered.length">
                <card-list [(model)]="applicationsFiltered" (onEdit)="onEdit( $event )" (onDelete)="onDelete( $event )" [overflow]="'auto'" [height]="'63vh'"
                    [sortAlphabetically]="true" [selectedIndex]="selectedApplicationIndex" [showVersions]="false"
                    [showSelect]="false" (onDebug)="onDebug( $event )" [showDebug]="false" [showType]="false"
                    (selectApplication)="selectApplication( $event )"
                    (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )" sortBy="Name"
                    [highlightWords]="searchList">
                </card-list>
            </ng-container>

        </mat-card-content>
    </mat-card-content>


    <mat-card-actions *ngIf="!isLoading" fxFlexAlign="end" align="end" style="position: absolute; width: 280px;bottom: 30px;" class="card-options-app-browser">
        <mat-divider></mat-divider>
        <div class="button-container">      
            <button mat-raised-button color="primary" class="toolbar-bottom-button" (click)="this.GetAllAppications()">{{
            (useCasesError ? 'Try again' : 'Refresh') }}</button>

        <button mat-raised-button *ngIf="!useCasesError" color="primary" class="toolbar-bottom-button" style="    margin-left: 13px; margin-right: 16px;"
            (click)="onCreate()">Add Application</button>   
        </div>
  
    </mat-card-actions>
</mat-card>