<mat-toolbar color="primary">
  <mat-toolbar-row>
    <mat-icon svgIcon="smart_objx_colorwhite" class="smart-objx"></mat-icon>
	<mat-divider class="style-fix" [vertical]="true"></mat-divider>
    <span class="rules">{{ title }}</span>
    <span class="toolbar-spacer"></span>
    <ng-container>
      <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngIf="!!user">
          <span mat-menu-item [disableRipple]="true">{{ user }}</span>
          <mat-divider></mat-divider>
        </ng-container>
          <button mat-menu-item (click)="logout()" >Log out</button>
      </mat-menu>
    </ng-container>
  </mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>

