<!-- <mat-drawer #drawer mode="side" [opened]="true"> -->
  <mat-card appearance="outlined" style="padding: 8px; height: 100%;" class="content">
    <button mat-flat-button
      class="change-perspective"
      [matMenuTriggerFor]="menu"
    >
      <!-- (click)="changePerspective()" -->
      CHANGE PERSPECTIVE
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menu>
      <ng-container *ngFor="let item of perspectives;">
          <button
            mat-menu-item (click)="changePerspective.emit( item )"
          >{{ item }}</button>
      </ng-container>
    </mat-menu>
    
    <mat-card-title class="title" style="border-bottom: unset; min-width: 266px;">
      <span>Perspective</span>
      <span class="toolbar-spacer"></span>
      <a color="primary" class="link" (click)="addPerspective.emit()">+ Add perspective</a>
    </mat-card-title>
    <mat-list *ngIf="perspectiveName">
      <mat-list-item class="custom">
        <div class="name">{{ perspectiveName }}</div>
        <div class="options">
          <button mat-icon-button (click)="configPerspective.emit()" matTooltip="settings">
            <mat-icon>settings</mat-icon>
          </button>
          <button mat-icon-button (click)="removePerspective.emit()" matTooltip="delete">
            <mat-icon>delete</mat-icon>
          </button>
          <custom-datepicker *ngIf="true"
          [(selectedDate)]="selectedDate"
          [initialDate]="selectedDate"
          [versionDates]="versionDates"
          [ruleId]="0"
          [isLoading]="versionsIsLoading"
          (onChange)="onChangeSelectedDate( record, $event )"
          (openVersions)="getVersions()"
          class="custom-datepicker"
          color="primary"
          [minDate]="firstVersionsDate"
      ></custom-datepicker>
          <button mat-icon-button (click)="savePerspective.emit()" matTooltip="save changes">
            <mat-icon>save</mat-icon>
          </button>
        </div>
      </mat-list-item>
    </mat-list>
    <mat-card-title class="subtitle subscriber" style="margin-top: 4px;">
      <span>SUBSCRIBER</span>
    </mat-card-title>
    <div class="text">
      <span>
        {{ subscriberName }} 
      </span>
    </div>
    
    <!-- (sort)="sort( items.organizations )" -->
    <div class="scroll">
      <perspective-list type="organizations"
        [items]="organizationsFilteredIn"
        [itemsOut]="organizationsFilteredOut"
        [disableOptions]="!selectedPerspective"
        [extraList]="organizationsInStructure"
        [extraLists]="organizationsInStructures"
        [isLoading]="isLoading"
        (filter)="filterOrganizations( $event )"
        [disableFilter]="!organizationsInStructure.length"
        [filterTitleCode]="filterStateOrganizations"
        (add)="addOrganization.emit()"
        (onEdit)="editOrganization.emit( $event )" 
        (onDelete)="deleteOrganization.emit( $event )" 
        (addChild)="addChild.emit( $event )"
        (removeItem)="removeItem.emit( $event )"
        
        (draggingItemChange)="draggingItemChange.emit( $event )"
      ></perspective-list>
      <perspective-list type="partners"
        [items]="partnersFilteredIn"
        [itemsOut]="partnersFilteredOut"
        [disableOptions]="!selectedPerspective || !organizationsInStructure.length"
        [extraList]="partnersInStructure"
        [extraLists]="partnersInStructures"
        [isLoading]="isLoading"
        [asPartner]="true"
        (filter)="filterPartners( $event )"
        [disableFilter]="!partnersInStructure.length"
        [filterTitleCode]="filterStatePartners"
        (add)="addPartner.emit()"
        (onEdit)="editPartner.emit( $event )"
        (onDelete)="deletePartner.emit( $event )" 
        (addChild)="addPartnerToPerspective.emit( $event )"
        (removeItem)="removeItem.emit( $event )"
      ></perspective-list>
    </div>
  </mat-card>
<!-- </mat-drawer> -->