import { Component, Inject, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent {
  title: string;
  titleClass: string;
  matIcon: string;
  content: string;
  value: string;
  fieldPlaceholder: string;
  button1Text: string;
  button2Text: string;
  hideButton1: boolean;
  hideButton2: boolean;
  button1Color: string;
  button2Color: string;

  customValidation: (value: any) => boolean;
  customValidationResult: boolean = true;
  customValidationMessage: string;

  @ViewChild('btnRef', { static: false }) buttonRef: MatButton;
  @ViewChild('inputRef', { static: false }) inputRef: any;
  

  constructor( @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<SimpleDialogComponent>
  ) {
    this.title = data.title;
    this.titleClass = data.titleClass;
    this.matIcon = data.matIcon || null;
    this.button1Text = data.button1Text || 'Yes, delete it';
    this.button2Text = data.button2Text || 'No, close';
    this.content = this.getContent(data.content);
    this.hideButton1 = data.hideButton1 || false;
    this.hideButton2 = data.hideButton2 || false;
    this.button1Color = data.button1Color || "warn";
    this.button2Color = data.button2Color || "primary";
    this.fieldPlaceholder = data.fieldPlaceholder;
    if(data.useField) {
      this.value = data.value;
      this.useField = true;
    }
    if(data.customValidation){
      this.customValidation = data.customValidation;
      this.customValidationMessage = data.customValidationMessage;
    }
    if(data.closeOnSeconds){
      setTimeout(() => {
        dialogRef.close()
      }, data.closeOnSeconds * 1000);
    }
  }
  
  private getContent(content: string): string{
    if(!content) return '';
    if(!~content.indexOf('\r\n'))
      return content;

    return content.split('\r\n').join('<br>');
  }

  acceptAndClose(){
    if(typeof this.customValidation !== 'undefined' && !this.customValidationResult)
      return;

    if(this.useField) {
      if(this.value){
        this.dialogRef.close(this.value);
      }
    } else {
      this.dialogRef.close(true);
    }
  }

  checkCustomValidation(){
    if(typeof this.customValidation === 'undefined'){
      this.customValidationResult = true;
    } else {
      this.customValidationResult = this.customValidation( this.value );
    }
  }
    
  ngAfterViewInit() {
    // if(this.fieldValue){
    //   setTimeout(() => {
    //     this.inputRef._elementRef.nativeElement.focus(); // not working
    //   }, 500);
    // } else {
      this.buttonRef.focus();
    // }
  }
  useField: boolean = false;

  get isNotValid(): boolean{
    return this.useField && (!this.value || !this.customValidationResult);
  }
}
