import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Role, SecurityApplication } from "@smartobjx/smart.objx.models";
import * as _ from "lodash";
import { ClusterNode, Edge, Layout, Node } from "@swimlane/ngx-graph";
import { Subject } from "rxjs";
import * as shape from "d3-shape";
import Mediator from "../core-services/mediator/access.mediator";
import { DagreNodesOnlyLayout } from "./DagreNodesOnlySettings";
import { forkJoin, of } from "rxjs";
import { Tools } from "../shared/Tools";

const getRandomColor = () =>
  "#" + ((Math.random() * 0xffffff) << 0).toString(16);

@Component({
  selector: "role-structure",
  templateUrl: "./role-structure.component.html",
  styleUrls: ["./role-structure.component.scss"],
})
export class RoleStructureComponent implements OnInit, OnChanges {
  OpenDeleteLink: boolean;
  selectedLink: Edge;
  enableParents: Node[] = [];
  isMouseDown: boolean;

  constructor(private mediator: Mediator) {}
  @Output() refreshApplication = new EventEmitter<boolean>();
  @Output() openrole = new EventEmitter<Role>();
  i_application: SecurityApplication;
  @Input()
  set application(newModel: SecurityApplication) {
    this.i_application = newModel;
  }
  get application() {
    return this.i_application;
  }

  public layoutSettings = {
    orientation: "TB",
    nodePadding: 100,
  };
  ngOnInit(): void {
    this.refreshModel();

    this.CloseDeleteLink();
  }

  onMouseDown(event: MouseEvent) {
    this.isMouseDown = true;
  }

  onMouseUp(event: MouseEvent) {
    this.isMouseDown = false;
    this.clearColors();
  }

  
  onMouseMove(event: MouseEvent,node) {
    if (this.isMouseDown) {
      this.loadEnableParents(node);
      node.style = "emisor";
      let self = this;
      for (let index = 0; index < this.nodes.length; index++) {
        if (node.id != self.nodes[index].id && this.enableParents.some(x=> x.id == self.nodes[index].id )) {
          let element = document.getElementById(self.nodes[index].id);
          const rect1Rect = element.getBoundingClientRect();
          let element2 = document.getElementById(node.id);
          const rect1Rect2 = element2.getBoundingClientRect();
          (self.nodes[index] as any).style = this.checkOverlap(rect1Rect, rect1Rect2)? "receptor" : ""  
        }
      }
    }
  }


  private CloseDeleteLink() {
    let self = this;
    window.addEventListener("click", function (e) {
      if (self.OpenDeleteLink) {
        var obj = document.getElementById("positioned-element");
        if (!obj.contains(e.target as any)) {
          if (obj.style.display == "block") {
            obj.style.display = "none";
            self.OpenDeleteLink = false;
          }
        }
      }
    });
  }
  loadEnableParents(node) {
    this.enableParents = this.nodes.filter(
      (itemNode: any) =>
        !node.datanode.PrimParentRoles.some(
          (itemSelected) => itemSelected.OID === itemNode.datanode.OID
        ) &&
        itemNode.datanode.OID != node.datanode.OID &&
        !this.links.some(
          (link) =>
            (link.source == itemNode.id && link.target == node.id) ||
            (link.source == node.id && link.target == itemNode.id)
        ) &&
        this.CheckHeritage(node.datanode, itemNode.datanode)
    );
  }
  deleteCloseButton() {
    var obj = document.getElementById("positioned-element");
    obj.style.display = "none";
    this.OpenDeleteLink = false;
  }

  refreshModel() {
    var model = this.i_application;
    this.nodes = (model as any).PrimChildren.map((x: Role) => {
      return {
        id: x.OID,
        label: x.Name,
        color: getRandomColor,
        datanode: x,
      };
    });
    let index = 0;
    let link = (model as any).PrimChildren.reduce((acc, x: Role) => {
      return acc.concat(
        (x as any).PrimParentRoles.map((Parent) => {
          index++;
          return {
            id: Tools.generateLinkID(),
            source: Parent.OID,
            target: x.OID,
          };
        })
      );
    }, []);
    this.links = link.filter((x) => !!x);
    console.log(this.links);
    this.update$.next(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.application) {
      this.refreshModel();
    }
  }
  zoomLevel;
  layout: String | Layout = new DagreNodesOnlyLayout(); //'dagre';
  curveType: string = "Bundle";
  curve: any = shape.curveLinear;
  draggingEnabled: boolean = true;
  panningEnabled: boolean = true;
  zoomEnabled: boolean = true;
  zoomSpeed: number = 0.1;
  minZoomLevel: number = 0.1;
  maxZoomLevel: number = 4.0;
  panOnZoom: boolean = true;
  autoZoom: boolean = false;
  autoCenter: boolean = false;
  update$: Subject<boolean> = new Subject();
  center$: Subject<boolean> = new Subject();
  zoomToFit$: Subject<boolean> = new Subject();
  nodes: Node[] = [];
  clusters: ClusterNode[] = [];
  links: Edge[] = [];
  clickOptions(node) {
    this.openrole.emit(node.datanode);
  }
  Changezoommanually() {
    this.zoomLevel = 3.8;
  }
  zoomChange($event, zoomLevel) {
    console.log($event, zoomLevel);

    this.zoomLevel = $event;
  }

  dragEnd($event) {
    this.center$.next(true);

    console.log($event);
  }

  clearColors() {
    this.nodes.forEach((x) => ((x as any).style = ""));
  }
 
  clickNode(nodeSelected: Node) {
    let self = this;
    for (let index = 0; index < this.nodes.length; index++) {
      if (nodeSelected.id != self.nodes[index].id) {
        let element = document.getElementById(self.nodes[index].id);
        const rect1Rect = element.getBoundingClientRect();
        let element2 = document.getElementById(nodeSelected.id);
        const rect1Rect2 = element2.getBoundingClientRect();
        function loadNewParent() {
          self.links.push({
            id: Tools.generateLinkID(),
            source: self.nodes[index].id,
            target: nodeSelected.id,
          }); //'a' + self.links.length + 1
          self.refreshApplication.emit(true);
        }
        if (this.checkOverlap(rect1Rect, rect1Rect2)) {
          if (
            !self.links.some(
              (link) =>
                (link.source == self.nodes[index].id &&
                  link.target == nodeSelected.id) ||
                (link.source == nodeSelected.id &&
                  link.target == self.nodes[index].id)
            )
          ) {
            (nodeSelected as any).datanode.PrimParentRoles.push(
              (self.nodes[index] as any).datanode
            );
            if (
              this.CheckHeritage(
                (nodeSelected as any).datanode,
                (self.nodes[index] as any).datanode
              )
            ) {
              this.mediator.saveAbsctract(
                this.application,
                (nodeSelected as any).datanode,
                loadNewParent
              );
            }
          }
          self.update$.next(true);
          console.log(self.links);
          break;
        }
      }
    }
  }

  CheckHeritage(nodeSelected, nodeTarget) {
    for (let index = 0; index < nodeTarget.PrimParentRoles.length; index++) {
      const element = nodeTarget.PrimParentRoles[index];
      if (element.OID == nodeSelected.OID) {
        return false;
      } else {
        return this.CheckHeritage(
          nodeSelected,
          nodeTarget.PrimParentRoles[index]
        );
      }
    }
    return true;
  }

  addParentWithMenu(nodeSelected: Node, child: Node) {
    let self = this;
    function loadNewParent() {
      self.links.push({
        id: Tools.generateLinkID(),
        source: child.id,
        target: nodeSelected.id,
      }); //'a' + self.links.length + 1
      self.refreshApplication.emit(true);
    }
    (child as any).datanode.PrimParentRoles.push(
      (nodeSelected as any).datanode
    );
    this.mediator.saveAbsctract(
      this.application,
      (child as any).datanode,
      loadNewParent
    );
  }

  checkOverlap(rect1, rect2) {
    return (
      rect1.x < rect2.x + rect2.width &&
      rect1.x + rect1.width > rect2.x &&
      rect1.y < rect2.y + rect2.height &&
      rect1.y + rect1.height > rect2.y
    );
  }

  showElement(event: MouseEvent) {
    this.OpenDeleteLink = true;
    const clickXw = event.clientX;
    const clickYw = event.clientY - 20;
    const positionedElement = document.querySelector(
      ".positioned-element"
    ) as HTMLElement;
    positionedElement.style.display = "block";
    positionedElement.style.top = clickYw + "px";
    positionedElement.style.left = clickXw + "px";

    // Ahora puedes hacer lo que necesites con las coordenadas absolutas clickX y clickY
    console.log(`Clic en X: ${clickXw}, Y: ${clickYw}`);
  }
  clickLink(event, Link: Edge) {
    this.selectedLink = Link;
    setTimeout(() => {
      this.showElement(event);
    }, 100);
  }

  DeleteLink() {
    let node = this.nodes.find((x) => x.id == this.selectedLink.target);
    let self = this;
    function loadNewParent() {
      self.links = self.links.filter((x) => x.id != self.selectedLink.id);
      self.refreshApplication.emit(true);
      self.deleteCloseButton();
      self.update$.next(true);
    }
    (node as any).datanode.PrimParentRoles = (
      node as any
    ).datanode.PrimParentRoles.filter((x) => x.OID != this.selectedLink.source);
    this.mediator.DeleteLinkRelationship(
      this.application,
      (node as any).datanode,
      loadNewParent
    );
  }
  DeleteAllLink($event: Role) {
    let node = this.nodes.find((x) => x.id == $event.OID);
    let self = this;
    function loadNewParent() {
      self.links = self.links.filter((x) => x.target != $event.OID);
      self.refreshApplication.emit(true);
      self.update$.next(true);
    }
    (node as any).datanode.PrimParentRoles = [];
    this.mediator.DeleteLinkRelationship(
      this.application,
      (node as any).datanode,
      loadNewParent
    );
  }

  DeleteNode(role: Role) {
    let node = this.nodes.find((x) => x.id == role.OID);

    (node as any).datanode.PrimParentRoles = [];
    console.log((this.application as any).PrimChildren);

    let application = _.cloneDeep(this.application) as any;

    application.PrimChildren = application.PrimChildren.filter(
      (child) => child.OID != role.OID
    );

    this.mediator.saveApplication(application).subscribe(() => {
      this.cleanParentInAnotherNodes(application, role);
    });
  }

  private cleanParentInAnotherNodes(application: any, role: Role) {
    let parentFound = application.PrimChildren.filter((child) => {
      return child.PrimParentRoles.some((parent) => parent.OID == role.OID);
    }).map((child) => {
      child.PrimParentRoles = child.PrimParentRoles.filter(
        (parent) => parent.OID != role.OID
      );
      return child;
    });
    if (!_.isNil(parentFound) && parentFound.length > 0) {
      const observables = parentFound.map((child) =>
        this.mediator.saveConfigWithApplication(this.application.OID, child)
      );

      forkJoin(observables).subscribe((responses: any) => {
        responses.forEach((response, index) => {
          console.log(`response for ${parentFound[index]}:`, response);
        });
        this.refreshApplication.emit(true);
        this.update$.next(true);
      });
    } else {
      this.refreshApplication.emit(true);
      this.update$.next(true);
    }
  }
}
