<span class="primary">Upload Manifest</span>
<br>
<ng-container *ngIf="!file">
    <ngx-file-drop (onFileDrop)="onFileDrop($event)">
        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
            <div style="display: flex;
        flex-direction: column;
        justify-content: unset;
    color: gray;
">
                <mat-icon class="uploadIcon">upload</mat-icon>
                <span>You can also upload your file by dropping here</span>
            </div>

        </ng-template>
    </ngx-file-drop>
</ng-container>
<ng-container *ngIf="file">
    <div class="loadContainer">
        <mat-icon class="doneIcon">done</mat-icon>
        <span>file loaded {{file.name}}</span>
    </div>

</ng-container>
<br>
<mat-divider></mat-divider>
<br>
<div style="display: flex;
justify-content: flex-end;">
    <button mat-raised-button color="primary" style="margin-right: 12px;" (click)="close()">close</button>

    <button mat-raised-button color="primary" [disabled]="!file" (click)="confirmSave()">Upload</button>
</div>