import { Component, OnInit, forwardRef } from '@angular/core';
import { MarketService, MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Organization, Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';


let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'app-login-portal',
  templateUrl: './login-portal.component.html',
  styleUrls: ['./login-portal.component.css']
})
export class LoginPortalComponent implements OnInit {

  pov: any;
  token: string;
  keep: boolean;
  demoSubscriptions: any[];
  selectedSubscription: any
  selectedPerspective: any;
  dataOwners: Organization[];
  perspectives: any;

  showPerspectives = false
  showOwner: boolean = false;
  constructor(private authService: AuthService,
    private mediator: Mediator,
    private route: ActivatedRoute,

    private _router: Router) { }

  ngOnInit() {

    this.route.queryParams
    .subscribe(params => {
      console.log(params);
      if (!_.isNil(params) && !_.isNil(params.token)) {
        this._router.navigate(
          ['/registerSubscription'],
          { queryParams: { offerMarket: params.token } }
        );
        //  this.mediator.ActiveMarketPlaceOffer(params.token).subscribe(token => {
        //    console.log(token)
        //  })
      }
    })

    this.mediator.GetDemoPerspectives("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", "BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849")
      .subscribe(subs => {
        this.demoSubscriptions = subs
        this.isLoadingTokens = false;
      })




  }
  perspectiveSelected() {
    this.isLoading = true
    this.getNestedClients(this.selectedPerspective.OID);
  }

  private getNestedClients(OID) {
    this.mediator.GetNestedClients("BF3FAE18-BECE-47EB-8FFA-3AA18C6DF849", this.selectedSubscription.AuthTokens[0].OID, OID)
      .subscribe(orgs => {
        this.showOwner = true
        this.isLoading = false; this.dataOwners = orgs;
      }, err => {
        if (err.status == 404) {
        }
      });
  }
  setDefaultToken(event) {
    // this.token = '5f87bf0e89a8427aaa5a8f4c01ffd777';
  }

  changeShowPerspectives() {
    if (!_.isNil(this.selectedSubscription) && this.selectedSubscription.AuthTokens.length > 0) {
      this.tokenSelected()
    }
  }
  tokenSelected() {
    this.token = this.selectedSubscription.AuthTokens[0].OID
    this.isLoading = true

      this.mediator.GetPerspectives(this.selectedSubscription.AuthTokens[0].OID, this.selectedSubscription.OID).subscribe((perspectives) => {
        this.showPerspectives = true
        this.perspectives = _.map(perspectives, (x:any) => { return { Name: x.Name, OID: x.OID } }).reduce((accumulator, current) => {
          if (!accumulator.find((item) => item.OID === current.OID)) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);
        this.isLoading = false
      })
  
  }
  login(event) {
    if (this.token != null && this.pov != null) {
      this.errors = '';
      this.authService.setTokenId(this.token);
      this.authService.setPOV(this.pov.toString());

      this.authService.userName = null;
      this.isLoading = true;
      this.mediator.checkLoginAndGetName(this.pov.OID.toString(), this.token, _.isNil(this.selectedPerspective)?this.pov.OID.toString(): this.selectedPerspective.OID)
      .subscribe({
        next: (sub: any) => {
          this.authService.userName = sub.name;
          this.authService.CanAdd = sub.canAdd
        },
        error: e => {
          console.error(e);
          this.isLoading = false;
          //this.hasErrors = true;
        },
        complete: () => {
          this.isLoading = false;
          localStorage.setItem('perspectiveSelected', _.isNil(this.selectedPerspective)?this.pov.OID.toString(): this.selectedPerspective.OID);

          if (this.keep) {
            localStorage.setItem('authToken', this.token);
            localStorage.setItem('authPov', this.pov.OID.toString());
          }
          this._router.navigate(['asd']);
        }
      });
  }
  }

  isLoading: boolean = false;
  isLoadingTokens: boolean = true;
  errors: string = '';
  tokens: any = [];
}
