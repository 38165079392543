<div mat-dialog-title [ngClass]="titleClass">
  <mat-icon *ngIf="matIcon">{{matIcon}}</mat-icon>
  <span>{{title}}</span>
</div>
<mat-dialog-content>
  <span [innerHTML]="content">
  </span>
  
  <mat-form-field *ngIf="useField" #inputRef [class.add-space]="content" (keyup.enter)="acceptAndClose()" (keyup)="checkCustomValidation()">
    <input matInput [(ngModel)]="value" [placeholder]="fieldPlaceholder"/>
  </mat-form-field>
  <mat-hint *ngIf="!customValidationResult && customValidationMessage" class="error">{{ customValidationMessage }}</mat-hint>
</mat-dialog-content>
<mat-dialog-actions fxFlexAlign="end" align="end">
  <button *ngIf="!hideButton1" mat-button [mat-dialog-close]="undefined" [color]="button1Color">{{ button1Text }}</button>
  <button *ngIf="!hideButton2" mat-raised-button [disabled]="isNotValid"
    (click)="acceptAndClose()"
    [color]="button2Color" #btnRef="matButton">{{ button2Text }}</button>
</mat-dialog-actions>