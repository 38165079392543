<div class="page">
  <mat-slider
  class="example-margin"
  [max]="4"
  [min]="0.1"
  [step]="0.1"
  #ngSlider><input matSliderThumb (change)="dragEnd({source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value})" #ngSliderThumb="matSliderThumb" [(value)]="zoomLevel" />


</mat-slider>
  <ngx-graph [links]="links" [nodes]="nodes" [clusters]="clusters" [layout]="layout" [curve]="curve"
    [draggingEnabled]="draggingEnabled" [panningEnabled]="panningEnabled" [enableZoom]="zoomEnabled"
    [zoomSpeed]="zoomSpeed" [minZoomLevel]="minZoomLevel" [maxZoomLevel]="maxZoomLevel" [panOnZoom]="panOnZoom" [zoomLevel]="zoomLevel"
    [autoZoom]="autoZoom" [autoCenter]="autoCenter" [update$]="update$" [center$]="center$" [zoomToFit$]="zoomToFit$"   [layoutSettings]="layoutSettings" (zoomChange) = "zoomChange($event,zoomLevel)"
    [layoutSettings]="layoutSettings" >

    <ng-template #defsTemplate>
      <svg:marker id="arrow" viewBox="0 -5 10 10" refX="8" refY="0" markerWidth="5" markerHeight="5"
        orient="auto-start-reverse">
        <svg:path d="M0,-5L10,0L0,5" class="arrow-head" />
      </svg:marker>
    </ng-template>

    <ng-template #clusterTemplate let-cluster>
      <svg:g class="node cluster">
        <svg:rect rx="5" ry="5" [attr.width]="cluster.dimension.width" [attr.height]="cluster.dimension.height"
          [attr.fill]="cluster.data.color" />
      </svg:g>
    </ng-template>

    <ng-template #nodeTemplate let-node>
      <svg:g class="node" xmlns="http://www.w3.org/2000/xhtml"  width="350" height="80" (click)="clickNode(node)"  (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mousemove)="onMouseMove($event,node)">
        <svg:foreignObject width="350" height="80">
          <xhtml:div class="cardContainer" xmlns="http://www.w3.org/1999/xhtml">

            <mat-card class="table-card main" [id]="node?.id" [ngStyle]="{'background-color': (node.style == 'emisor' ? '#ff7f7f' : ( node.style == 'receptor' ? '#8dff7f' : 'white'))}">
              <div style="display: flex;
              justify-content: space-between;">
                <div style="font-size: 24px;
              font-weight: 500;">{{ node.label }}</div>
                <div>
                 
                  <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="options" class="option-button">
                    <mat-icon style="margin-top: -11px;" (click)="loadEnableParents(node)">more_vert</mat-icon>
                    <mat-menu #menu>
                      <button mat-menu-item (click)="clickOptions(node)"> <mat-icon>settings</mat-icon>Edit Role</button>

                      <ng-container *ngIf="enableParents.length>0">
                        <button mat-menu-item [matMenuTriggerFor]="editMenu" > <mat-icon>add</mat-icon>Add Parent</button>
                      </ng-container>
          
                      <button mat-menu-item *ngIf="node?.datanode?.PrimParentRoles?.length > 0" (click)="DeleteAllLink(node?.datanode)"  > <mat-icon>link_off</mat-icon> Delete parents</button>
                      <ng-container *ngFor="let item of menuItems; let firstItem = first;">
                        <button *ngIf="useCustomMenu( item.type ); else nomenu" mat-menu-item
                          (click)="actionByType( item )" [matMenuTriggerFor]="customMenu"
                          [matMenuTriggerData]="{orgType: item.type}">{{ item.name }}</button>
                        <ng-template #nomenu>
                          <button mat-menu-item (click)="actionByType( item )">{{ item.name }}</button>
                        </ng-template>
                      </ng-container>
                    </mat-menu>
                    <mat-menu #customMenu="matMenu">
                      <ng-template matMenuContent let-action="orgType">
                        <button mat-menu-item (click)="newItem( action )">+ Add new</button>
                        <button mat-menu-item
                          *ngFor="let item of (action == 'addPartner' ? availablePartners : availableOrganizations);"
                          (click)="actionByTypeFor( item, action )">{{ item.Name }}</button>
                      </ng-template>
                    </mat-menu>
                    <mat-menu #editMenu="matMenu">
                

                        <button mat-menu-item
                        *ngFor="let item of enableParents;"
                        (click)="addParentWithMenu( item, node )">{{ item?.datanode?.Name }}</button>
                   
                    </mat-menu>

                  </button>
                </div>
              </div>

              <div class="border-identifier"></div>


            </mat-card>

          </xhtml:div>
        </svg:foreignObject>
      </svg:g>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg:g class="edge" (click)="clickLink($event,link)">
        <svg:path class="line" stroke-width="3" marker-start="url(#arrow)">
        </svg:path>
        <svg:text class="edge-label" text-anchor="middle">
          <textPath class="text-path" [attr.href]="'#' + link.id" [style.dominant-baseline]="link.dominantBaseline"
            startOffset="50%">
            {{link.label}}
          </textPath>
        </svg:text>
      </svg:g>
    </ng-template>
  </ngx-graph>
  <div #positionedElement id="positioned-element" class="positioned-element">
    <div> delete this link</div>
    <div style="display: flex;justify-content: center;">
      <button mat-icon-button (click)="DeleteLink()">
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-icon-button (click)="deleteCloseButton()">
        <mat-icon>close</mat-icon>
      </button>
    </div>


  </div>
</div>