import { Component, OnInit, ViewChild, Inject } from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Organization } from '@smartobjx/smart.objx.models';

@Component({
    selector: 'add-organization-dialog',
    templateUrl: './add-organization-dialog.component.html',
    styleUrls: ['./add-organization-dialog.component.scss']
})
export class AddOrganizationDialogComponent implements OnInit {

    form: UntypedFormGroup;
    description:string;
    title:string;

    customValidation: (value: any) => boolean;
    customValidationResult: boolean = true;
    customValidationMessage: string;

    checkCustomValidation(){
        if(typeof this.customValidation === 'undefined'){
          this.customValidationResult = true;
        } else {
          this.customValidationResult = this.customValidation( this.organization.Name );
        }
    }

    constructor(
        private fb: UntypedFormBuilder,
        private dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: any
    ) {
        if(data){
            if(data.organization) {
                this.organization = Object.assign({}, data.organization);
            }else {
                this.organization.Name = "";
            }
            this.organization.IsPartner = data.isPartner;

            if(data.customValidation) {
                this.customValidation = data.customValidation;
                this.customValidationMessage = data.customValidationMessage;
            }
            // console.log(data);
        }
        // this.form = fb.group({
        //     description: [data.description, Validators.required],
        //     category: [data.category, Validators.required],
        //     longDescription: [data.longDescription,Validators.required]
        // });
    }

    ngOnInit() {
        this.showUpdate = typeof this.organization.OID != 'undefined';
        this.form = this.fb.group({
            description: [this.description, []],
        });
    }

    save() {
        this.dialogRef.close( this.organization );
        // this.dialogRef.close(this.form.value);
    }

    close() {
        this.dialogRef.close();
    }

    get isNotValid(): boolean {
        return !this.organization.Name || !this.customValidationResult;
    }

    organization: Organization = new Organization();
    showUpdate: boolean = false;
}