
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import * as _ from "lodash";

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

    constructor(
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem('token');

        let request = req;
        var pov = req.headers['get']('Ocp-Apim-POV-Key')
        var key = req.headers['get']('Ocp-Apim-Subscription-Key')
        if (!_.isNil(pov) && !_.isNil(key)) {
            pov = pov.replace(/-/g, '');
            key = key.replace(/-/g, '');
            let headerRequest = {}
            headerRequest['Ocp-Apim-POV-Key'] = pov
            headerRequest['Ocp-Apim-Subscription-Key'] = key
            request = req.clone({
                setHeaders: headerRequest
            });
        }

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse) => {

                if (err.status === 401) {
                    this.router.navigateByUrl('/login');
                }

                return throwError(err);

            })
        );
    }

}