import { Injectable } from '@angular/core';
import { SettingsService, StructuresService } from '@smartobjx/smart.connectors';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Tools } from 'src/app/shared/Tools';
import { AbstractConfiguration, AbstractRule, Perspective } from '@smartobjx/smart.objx.models';
import { CustomValidator } from 'src/app/shared/Validation';
import { AuthService } from '../authentication/auth.service';

export function factory() {
  return (_server: StructuresService, _auth: AuthService): Mediator => {
    return new Mediator(_server, _auth);
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {
  constructor(private server: StructuresService, private auth: AuthService) { }



  findRuleOnWithVersionInfo(ruleID: string, date: Date) {
 
    // return this.server.findRuleOnWithVersionInfo(ruleID, Tools.dateToURLStringAsDate(date))
    //   .pipe(tap(
    //     (data: any) => {
    //       view.Loading = false;
    //       view.updateModel(data.rule as AbstractRule);
    //       view.updateData({ changedChildren: data.changedChildren });
    //     },
    //     e => {
    //       view.Loading = false;
    //     }
    //   )
    //   );
  }

  changeVersion(rule: AbstractConfiguration, date: Date) {
    // let viewRef = this.viewController.getView(rule);
    // if (CustomValidator.is(rule, 'ruleset')) {
    //   this.findRuleOnWithVersionInfo(viewRef, rule.OID, date).toPromise();
    // } else {
    //   this.findConfigOn(viewRef, rule.OID, date).toPromise();
    // }
  }

  findConfigOn(  configID: string, date: Date ): Observable<Perspective>{
    return this.server.findPerspectiveOn( configID, Tools.dateToURLStringAsDate( date ) )
        .pipe( tap(
            ( abstractConfig: Perspective ) => {
              //  view.Loading = false;
              //  view.updateModel( abstractConfig );
            },
            e => { 
              //view.Loading = false; 
            }
        )
    );
  }

  editRule(parent: AbstractConfiguration, selectedDate: Date, showTime: boolean, isNotInitialDate: boolean, callback: null) {
     let rule = Tools.createCopy(parent);
     let date = CustomValidator.ensureDate(selectedDate);
    
     const observable = new Observable(subscriber => {
//this.viewController.clearBy(parent);
       // find this rule with version
       let viewRef = this.prepareByType(rule);
       let data = {
         versionDateData: { date, showTime: showTime, altClass: isNotInitialDate },
         subscriber
       };
      // viewRef.replaceData(Object.assign({}, viewRef.Data, data));
       this.findConfigOn( parent.OID, date).toPromise();
     });
    
     observable.subscribe({
       error: e => console.error(e),
       complete: callback
     });
  }


  private prepareByType(config: AbstractConfiguration, parent?: AbstractConfiguration) {
    if (CustomValidator.is(config, 'setting')) {
   //   return this.viewController.showSetting(config, parent);
    } else if (CustomValidator.is(config, 'configuration')) {
   //   return this.viewController.showConfiguration(config, parent);
    }

  }



}