<mat-card appearance="outlined" class="full-height mat-main-card access" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline" style="min-width: 266px;">{{newApplication? 'NEW': 'EDIT'}} APPLICATION  </mat-card-title>
  <mat-icon class="close" (click)="closeApplication()">close</mat-icon>
  <ng-container *ngIf="!isLoading">
    <mat-card-content style="height: 75vh">
      <version-caption *ngIf="versionDateData" [selectedDate]="versionDateData.date"
        [showTime]="versionDateData.showTime" [isNotInitialDate]="versionDateData.altClass"></version-caption>
      <form [formGroup]="form">
        <mat-form-field class="custom" style="margin-top: 13px;">
          <mat-label>Name</mat-label>
          <input matInput  formControlName="name" #nameInput />
        </mat-form-field>
        <mat-form-field class="custom">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" #nameInput />
        </mat-form-field>
        <custom-date [(model)]="i_startDate" [(forceInvalid)]="startDateForceInvalid" style="margin-top: -26px;"></custom-date>
        <!-- list of child rules -->
        <ng-container *ngIf="!this.isNew">
          <mat-card-title class="rules-title">
            <span>Roles</span>
            <span class="toolbar-spacer"></span>
            <a [matMenuTriggerFor]="add" color="primary" class="link">+ Add</a>
            <mat-menu #add [overlapTrigger]="false">
              <button mat-menu-item (click)="appendRole()">Role</button>

            </mat-menu>

          </mat-card-title>

          <div *ngIf="model?.PrimChildren && model?.PrimChildren.length == 0" class="h5 no-children">No Roles
            defined</div>
        </ng-container>
      </form>

      <ng-container *ngIf="!this.isNew && model">

        <card-list [(model)]="model.PrimChildren" (onEdit)="appendAbstract( $event )" (onDelete)="onDelete( $event )" [overflow]="'auto'" [height]="'43vh'" (onDeleteAllRelationship)="DeleteAllRelationship($event)"
          (onDebug)="onDebug( $event )" [showDebug]="false" [showDelete]="_authService.CanAdd"  [showType]="false"
          (onStats)="showStats($event)" (onVersions)="onVersions( $event.record, $event.date, $event.versionDates )"
          (onChanges)="onChanges()" [selectedIndex]="selectedIndex" [forceRuleStatus]="disableOrEnableStack" [showDelete]="true"
          showType="true" [pov]="pov" [showVersions]="false" hideOptions="true" showStats="true" [showDeleteAllRelationship]="true"></card-list>
      </ng-container>

    </mat-card-content>
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end" class="card-options-application-editor">
      
      <mat-divider></mat-divider>
      <div class="button-container">
        <button mat-raised-button type="button" color="primary" class="toolbar-bottom-button" [disabled]="formIsDisabled"
        (click)="this.onSaveModel()">Save application</button>
      </div>
   
    </mat-card-actions>
  </ng-container>

  <!-- POP UPS -->
  <ng-template #startDateMinVerification>
    <div class="h6 start-date-message" matDialogTitle>
      <mat-icon>warning</mat-icon>
      <div class="text">Attention</div>
    </div>
    <mat-dialog-content class="start-date-message">


      The chosen Start date has already passed. <br />
      Do you want to make these changes active immediately?

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose (click)="fixStartDateAndSave()">Yes</button>
      <button mat-raised-button matDialogClose color="primary" (click)="markStartDateAsDirty()">No</button>
    </mat-dialog-actions>
  </ng-template>
</mat-card>