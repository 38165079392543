import { Component, Input, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { Configuration, AbstractConfiguration, AbstractSecurityElement, SecurityApplication, Application, Role, Entitlement, Privilege } from '@smartobjx/smart.objx.models';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Mediator from '../core-services/mediator/access.mediator';
import { CustomValidator } from '../shared/Validation';

@Component({
    selector: 'entitlement-editor',
    templateUrl: './entitlement-editor.component.html',
    styleUrls: ['./entitlement-editor.component.scss'],
})
export class EntitlementEditorComponent implements OnInit, OnDestroy {
    @Input() newRole: boolean;
    @Output() closeRole = new EventEmitter<boolean>();
    @Output() closeEntitlement = new EventEmitter<boolean>();
    roleSelected
    isActive
    isLoading = false
    constructor(
        private mediator: Mediator,
        public _dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private _authService: AuthService,
        private _info: MatSnackBar
    ) {

        this.minDate = new Date();
        this.advOptEnabled = false;
        this.pov = this._authService.getPOV();
    }

    get model(): Entitlement {
        return this.i_Model;
    }


    set model(newModel: Entitlement) {
        this.i_Model = newModel;
        this.selectedIndex = -1;
    }

    set roleModel(roleModel: Role) {
        this.i_RoleModel = roleModel;
        this.selectedIndex = -1;
    }
    get roleModel(): Role {
        return this.i_RoleModel;
    }
    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }


    get formIsDisabled(): boolean {
        return !this.form || (this.form.pristine && this.deleteStack.length === 0) || !this.form.valid;
    }
    get priorToParent(): boolean {
        const now = new Date();
        return now < this.minDate;
    }

    // the parent date (will be used as minDate)

    minDate: Date;
    initialDate: Date; // the current version date
    advOptEnabled: boolean;

    isNew: boolean

    private get isUntouched(): boolean {
        return this.form.pristine && this.deleteStack.length == 0;
    }

    private i_startDate: Date = new Date();
    startDateForceInvalid: boolean = false;
    // #endregion

    // #region Data Elements
    private i_Model: Entitlement;
    private i_RoleModel: Role;


    private i_SelectedIndex: number;
    private form: UntypedFormGroup;
    private deleteStack: any[] = [];
    private pov: string;
    versionsIsLoading = false




    @ViewChild('nameInput', { static: false }) nameInputRef: any;
    private disableFocused: boolean = false;
    fixDateValue: string;
    @ViewChild('startDateMinVerification', { static: false }) startDateMinVerificationDialog: any;
    // #endregion

    // #region Event Emitters
    // #endregion
    // #region Event Handlers

    // #region public
    onSaveModel() {
        if (this.isUntouched) return;
        const date = this.i_startDate;
        if (this.checkDateValues(date)) {
            let model = this.mergeFormToModel();
            const resetForm = () => {
                this.form.reset();
                this.deleteStack = [];
            };

            const refresh = () => {
                this.closeEntitlement.emit(true)
            };

            if (this.deleteStack.length) {
            } else {
                this.mediator.saveEntitlement(this.roleModel, model, refresh);
            }

        } else {
            this.openDialog(this.startDateMinVerificationDialog);
        }
    }


    openDialog(el: any, options: any = null) {
        return this._dialog.open(el, options);
    }
    checkStartDateBefore(fn: (startDate: Date) => void) {
        const startDate = this.i_startDate;
        if (!startDate) {
            this.fireStartDateError();
            return;
        }

        const now = new Date();
        const version = startDate > now ? startDate : now;

        fn(version);
    }
    // #region from view

    appendRole(settingModel = null) {


    }

    fireStartDateError() {
        this._info.open('Start Date must be valid before this action', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-warn'
        });
    }

    onDisableOrEnable(config: AbstractConfiguration) {
        this.updateSelected(config);
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: `Yes, delete it`,
                content: `Do you really want to delete the `
                    + this.getType(config)
                    + ` "<b>${config.Name}</b>" ?`
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                this.selectedIndex = -1;
                if (action) {
                    this.stackToDelete({ configuration: config })
                }
            });
    }

    onDelete(config: AbstractConfiguration) {
        this.updateSelected(config);
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Are you sure?',
                titleClass: 'error',
                matIcon: 'delete_forever',
                content: 'Do you really want to want to delete the '
                    + this.getType(config)
                    + ` "<b>${config.Name}</b>" ?`
                    + '\r\nThis process cannot be undone.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur();
                if (action) {

                    let primChildren = (this.roleModel as any).PrimChildren,
                        selectedIndex = primChildren.indexOf(config);
                    primChildren.splice(selectedIndex, 1);
                    this.stackToDelete({ configuration: config })

                    //     this.viewController.clearBy(this.model);
                }
            });
    }
    onChanges() {
        (this.form as any).pristine = false;
    }
    checkDateValues(date: Date) {
        return date >= this.minDate;
    }

    toggleAdvancedOptions() {
        this.advOptEnabled = !this.advOptEnabled;
    }

    simpleDate(date: any) {
        return Tools.dateToString(date);
    }
    markStartDateAsDirty() {
        this.startDateForceInvalid = true;
    }
    fixStartDateAndSave() {
        this.i_startDate = this.priorToParent ? this.minDate : new Date();
        this.onSaveModel();
    }
    fixMinDate(date: Date) {
        const diff = this.minDate.getTime() - date.getTime();
        if (diff > 0 && diff < 100) {
            this.minDate = date;
        }
    }
    // #endregion

    // #endregion

    // #region private
    private checkStartDate() {
        //   const { startDate } = this;
        //   if (startDate) {
        //       this.minDate = startDate;
        //   }
    }
    private updateSelected(rule: AbstractConfiguration) {
        this.selectedIndex = (this.roleModel as any).PrimChildren.indexOf(rule)
    }

    private getType(record: any) {
        let type = (record as any).$type;
        if (!type) return 'undefined';
        if (type.includes('Settings.Configuration,')) return 'configuration';
        if (type.includes('Settings.Setting,')) return 'setting';
        if (type.includes('Settings.SecureSetting,')) return 'setting';
    }

    private getControl(name: string): UntypedFormControl {
        return this.form.get(name) as UntypedFormControl;
    }

    private mergeFormToModel() {

        let model = Object.assign({}, this.model as any);
        model.Name = this.form.value.name;
        model.Description = this.form.value.description;
        model.Privilege = new Privilege({Name:this.form.value.permission}) 
        model.Version = this.i_startDate;
        return model;
    }

    private stackToDelete(data: any) {
        let filter = this.deleteStack.filter(o => o.configuration === data.configuration);
        if (filter.length) {
            const i = this.deleteStack.indexOf(filter[0]);
            this.deleteStack.splice(i, 1);
        } else {
            this.deleteStack.push(data);
        }
    }
    private showWarning() {
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: 'Yes, close the panel',
                button2Text: 'No, keep the panel open',
                button1Color: 'primary',
                content: 'Are you sure you want to close this panel?'
                    + '\r\nThe changes won´t be saved.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                if (action) {
                    //    this.view.close(true);
                }
            });
    }
    private registerEvents() {
        // this.view.Events.onClose$ = () => {
        //     if (!this.isUntouched) {
        //         this.showWarning();
        //     }
        //     return this.isUntouched;
        // };
    }
    // #endregion
    // #endregion

    // #region Construction & Finalization

    CloseRoleBtn() {
        this.closeEntitlement.emit(false)
    }


    ngOnDestroy() {
    }

    ngOnInit() {
        this.model = new Entitlement()
        let model = this.model;
        this.form = this.fb.group({
            name: [{ value: model.Name, disabled: false }, Validators.required],
            description: [model.Description],
            permission: [model]
        });
    }
    LoadModel(parent,modelInput) {
        this.roleModel = parent
        this.model = modelInput
        this.isNew = Tools.isEmptyGuid(this.model.OID)
        this.checkStartDate();
        this.registerEvents();
        let model = this.model;

        if (typeof model.Version === 'string' && !!model.Version) {
            model.Version = CustomValidator.ensureDate(model.Version);
        }
        this.form = this.fb.group({
            name: [{ value: model.Name, disabled: false }, Validators.required],
            description: [model.Description],
            permission: [model.Privilege.Name]
        });
        this.i_startDate = new Date(model.Version.getTime());
        this.initialDate = model.Version;
        this.fixMinDate(model.Version);
    }

    ngOnChanges(changes: any): void {
        console.log(this.form)
        this.checkStartDate();
        if (changes.model && !changes.model.firstChange) {
            let model = changes.model.currentValue;

            if (typeof model.Version === 'string' && !!model.Version) {
                model.Version = CustomValidator.ensureDate(model.Version);
            }
            this.form.patchValue({
                name: model.Name,
                description: model.Description,
                permission: model.Privilege.Name
            });
            this.i_startDate = new Date(model.Version.getTime());
            this.initialDate = model.Version;
        }

        // focus name
        if (changes.isLoading && changes.isLoading.currentValue == false && !this.disableFocused) {
            setTimeout(() => {
                this.nameInputRef.nativeElement.focus();
                this.disableFocused = true;
            }, 0);
        }
    }
    updateConfiguration() {

        //  this.mediator.updateConfiguration(this.model.OID,this.viewController).subscribe(model => {
        //      this.model = model as Configuration;
        //      this.view.Model = model as Configuration;
        //      let parentComponent = this.viewController.getParentComponent(model);
        //      if (!_.isNil(parentComponent)) {
        //          parentComponent.updateConfigurationList();
        //      } else {
        //          this.viewController.updateRootForReplace.next()
        //      }
        //  })
    }

    showStats(model) {
        // this.mediator.newStats(this.view, model, this,true,this.viewController);
    }

    // #endregion


}