import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RuleSet, UseCase, Application } from '@smartobjx/smart.objx.models';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { environment } from 'src/environments/environment';
import Mediator from '../core-services/mediator/access.mediator';

import { Inject } from '@angular/core';




@Component({
  selector: 'upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
  i_NameApplication: string;
  updateRefresh: any;
  onDestroy$: Subject<boolean> = new Subject();
  mediator: any;
  file: File;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,

    private dialogRef: MatDialogRef<UploadFileComponent>,
    public _dialog: MatDialog,
    private authService: AuthService,
  ) { }
  ngOnInit() {
    // will log the entire data object
    console.log(this.data)
    this.mediator = this.data.mediator
  }

  confirmSave(){
    this.openDialog(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: false,
      data: {
          title: 'Attention',
          titleClass: 'warning',
          matIcon: 'warning_amber',
          button1Text: `'close'`,
          button2Text: 'Yes, save it',
          content: `Do you really save this application?`,
          useField: false,
          value: true
      }
  }).afterClosed().toPromise()
      .then(action => {
          (document.activeElement as any).blur(); // fix force blur on x
          if (action) {
            this.SaveManifest(this.file).subscribe((x) => {this.dialogRef.close(true)})
          }
      });
  }

  openDialog(el: any, options: any = null) {
    return this._dialog.open(el, options);
}

close(){
  this.dialogRef.close()
}

SaveManifest(body) {
    let headerRequest = {}
    headerRequest['Content-Type'] = "multipart/form-data;"
    const formData = new FormData();
    formData.append('model', body);

    let headers = {};

    headers['Ocp-Apim-POV-Key'] = this.mediator.server.configuration.POVToken;
    headers['Ocp-Apim-Subscription-Key'] = this.mediator.server.configuration.SubscriberToken

    return this.authService.httpClient.post(`${environment.accessServiceBasePath}/manifest/`, formData,
      {
        withCredentials: null,
        headers: headers,
      })
  }

  onFileDrop(event: NgxFileDropEntry[]): void {
    if (event.length > 0) {
      const file = event[0];
      this.validateAndReadJSON(file);
    }
  }

  private validateAndReadJSON(file: NgxFileDropEntry): void {
    if (file.fileEntry.isFile) {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (file.type != "application/json") { console.log("Invalid file"); return; }
        this.file = file
      

      
        var reader = new FileReader();
      })
      //  let self = this
      //    reader.onload = function(event) {
      //
      //       var serilizedJson =event.target.result   
      //       self.ReadManifest(JSON.stringify(serilizedJson)).subscribe((x)=> console.log(x))        
      //    }
      //    reader.readAsText(file)
      //
      //  });
    }
  }

}
