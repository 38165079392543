import { Injectable } from '@angular/core';
import { AccessService, SettingsService, StructuresService } from '@smartobjx/smart.connectors';
import { environment } from 'src/environments/environment';
import { AuthService } from '../authentication/auth.service';
import { Observable } from 'rxjs';
import { Perspective } from '@smartobjx/smart.objx.models';

export function factory() {
  return (
    _server: StructuresService, _settingServer: SettingsService , _authService:AuthService,_accessService:AccessService
  ): Mediator => {
    return new Mediator(_server, _settingServer,_authService,_accessService);
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {

  constructor(
    private server: StructuresService, private settingServer: SettingsService,private _authService:AuthService,private _accessService:AccessService
  ) { }

  checkLoginAndGetName(ownerId: string, subscriberToken: string,perspective) {
    this._accessService.configuration.SubscriberToken = subscriberToken;
    this._accessService.configuration.POVToken = ownerId;
    return this._accessService.checkLoginAndGetName(perspective);
  }


  GetDemoPerspectives(ownerId: string, subscriberToken: string) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.getLineageForDemo()
  }

  GetPerspectives(subscriberToken: string, ownerId: string) :Observable<Array<Perspective>>{
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.getAllPerspectives()
  }


  
  GetNestedClients(ownerId: string, subscriberToken: string , perspectiveId) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.findNestedClients(perspectiveId)
  }

  ActiveMarketPlaceOffer(token, companyName) {


    let headerRequest = {}
    headerRequest['Content-Type'] = "application/json"
    let body= {marketOffer:token,companyName:companyName}
    return this._authService.httpClient.post(`${environment.SubcriptionService}/ResolveStructuresOffer/`,body,
      {
        withCredentials: null,
        headers: headerRequest,
      })
  }
}