<!-- 
  <div *ngIf="!editMode && colorLevels" class="demo-color-level">
    <div *ngFor="let color of colorLevels; index as i" [ngStyle]="{ backgroundColor: color }" [title]="color">
      level {{ i | number: '2.' }}
    </div>
  </div> -->
<!-- selectedBranch -->

<div class="view-options" *ngIf="showOptions()">
  <!-- <div class="view-options" *ngIf="!selectedBranch"> -->
  <button mat-button (click)="close.emit();" class="go-back-button">
    Go back
    <!-- Go back from {{ branch.Organization.Name }} -->
  </button>
</div>

<div [ngStyle]="{ padding: '10px 0 0 50px', position: 'absolute' }" *ngIf="!selectedBranch && hierarchyInfo"
  class="hierarchy-info">
  <!-- branch: {{ branch.Organization.Name }} <br /> -->
  {{ hierarchyInfo }}
  <!-- hierarchyInfo: {{ hierarchyInfo ? hierarchyInfo : 'none' }} <br /> -->
</div>

<ng-container *ngIf="selectedBranch">
  <structure-branch-editor-cdk [zoom]="zoom" [branch]="selectedBranch"
    [organizationsInStructure]="organizationsInStructure" (itemMovedOrTransfered)="itemMovedOrTransfered.emit()"
    [hierarchyInfo]="getHierarchyFor( selectedBranch )" (remove)="remove.emit( $event )"
    (permissionFor)="permissionFor.emit( $event )" (close)="selectedBranch=null;"
    (closeAndRemove)="doCloseAndRemove( $event )" [disableAddPartner]="true" (addChildAs)="addChildAs.emit( $event );"
    [clientAPI]="clientAPI" (newOrganization)="newOrganization.emit( $event );"
    [availableOrganizations]="availableOrganizations"></structure-branch-editor-cdk>
</ng-container>

<ng-container *ngIf="!selectedBranch">
  <div class="graph-layout" [ngStyle]="{'transform': zoom, 'transform-origin': '0 0' }">
    <ng-container *ngTemplateOutlet="node;context:{item:  branchWithRelation}"></ng-container>
    <ng-container *ngTemplateOutlet="nodeRelation;context:{item: branchWithoutRelation}"></ng-container>
  </div>
</ng-container>



<ng-template #extraDataContainer let-extradataEl="extradataEl" let-item="item">
  <mat-progress-bar *ngIf="extradataEl.loading;" mode="indeterminate"></mat-progress-bar>

  <ng-container *ngIf="extradataEl.error">
    <mat-error>{{ extradataEl.error }}</mat-error> <!-- ERROR -->

    <div class="extra-data-save-button">
      <button mat-raised-button color="primary"
        (click)="showExtraData( extradataEl, {}, item.Organization.OID, true )">TRY AGAIN</button>
    </div>
  </ng-container>

  <ng-container *ngIf="extradataEl.types"> <!-- TYPE COMBO -->
    <mat-form-field>
      <mat-label>Select a type</mat-label>
      <mat-select (selectionChange)="getExtraDataTypeInfo( $event, extradataEl )" [(ngModel)]="extradataEl.type">
        <mat-option *ngFor="let item of extradataEl.types" [value]="item.value">
          {{ item.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </ng-container>

  <ng-container *ngIf="extradataEl.properties">
    <ng-container *ngFor="let propName of extraDataKeys( extradataEl.properties );">
      <div>
        <mat-form-field
          *ngIf="extraDataType( extradataEl.properties, propName ) === 'string' else extradatatypeboolean">
          <input matInput [(ngModel)]="extradataEl.record[propName]"
            [placeholder]="extraDataName( extradataEl.properties, propName )" />
        </mat-form-field>

        <ng-template #extradatatypeboolean>
          <mat-slide-toggle color="primary" [(ngModel)]="extradataEl.record[propName]"
            [checked]="extraDataValue( extradataEl.properties, propName ) ? 'checked' : undefined">
            {{ extraDataName( extradataEl.properties, propName ) }}
          </mat-slide-toggle>
        </ng-template>
      </div>
    </ng-container>

  </ng-container>
  <div class="extra-data-save-button" *ngIf="extradataEl.type">
    <button mat-raised-button color="primary" [disabled]="!extradataEl.type || extradataEl.loading"
      (click)="saveExtraData( extradataEl, item.Organization )">SAVE</button>
  </div>
</ng-template>

<ng-template #mainCard let-item="item" let-childControl="childControl">
  <div class="main-card-content" cdkDropList [cdkDropListData]="item" (cdkDropListDropped)="drop($event)">
    <!-- [class.hasOnlyOneChild]="item.PrimChildren.length === 1" -->
    <mat-card appearance="outlined" class="table-card main">
      <mat-card-title>{{item.Name}}</mat-card-title>
      <mat-card-subtitle>{{item.Tag}}</mat-card-subtitle>

      <div class="actions">
        <span></span> <!-- flex -->
        <span [class.disabled]="permissions.viewDisabled" (click)="toggleInfo( moreinfo, extradataEl );" #moreinfo>
          {{ moreinfo.expanded ? 'Hide' : 'View'}} permissions
          <mat-icon *ngIf="!moreinfo.expanded">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="moreinfo.expanded">keyboard_arrow_up</mat-icon>
        </span>
        <span [class.disabled]="permissions.manageDisabled" (click)="permissionFor.emit()">
          <mat-icon>settings</mat-icon>
        </span>
        <span class="divider"></span>
        <span (click)="showExtraData( extradataEl, moreinfo, item.Organization.OID )" #extradataEl>More</span>
      </div>

      <div *ngIf="!permissions.viewDisabled && moreinfo.expanded" class="more-info">
        <div>
          <div class="partners">Partners:</div>
          <div>items</div>
        </div>
        <div>
          <div class="customers">Customers:</div>
          <div>items</div>
        </div>
      </div>

      <div *ngIf="extradataEl.expanded" class="extra-data">
        <ng-container
          *ngTemplateOutlet="extraDataContainer;context:{ extradataEl: extradataEl, item: item }"></ng-container>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="options" class="option-button">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <button mat-menu-item (click)="closeAndRemove.emit( item )">Remove</button>
        <!-- [matMenuTriggerData]="{orgType: item.type}" -->
        <button mat-menu-item [matMenuTriggerFor]="addChildCustomMenu">Add child</button>
        <button mat-menu-item [matMenuTriggerFor]="addPartnerCustomMenu" *ngIf="!disableAddPartner">Add partner</button>
      </mat-menu>

      <mat-menu #addChildCustomMenu="matMenu">
        <ng-template matMenuContent> <!-- let-action="orgType" -->
          <button mat-menu-item (click)="newOrganization.emit( { item: item } ); console.log(1);">+ Add new</button>
          <button mat-menu-item *ngFor="let org of availableOrganizations;"
            (click)="addChild( org, item ); console.log(2);">{{ org.Name }}</button>
        </ng-template>
      </mat-menu>

      <mat-menu #addPartnerCustomMenu="matMenu">
        <ng-template matMenuContent> <!-- let-action="orgType" -->
          <button mat-menu-item (click)="newOrganization.emit( { item: item, isPartner: true } ); console.log(3);">+ Add
            new</button>
          <button mat-menu-item *ngFor="let org of availablePartners;"
            (click)="addAsPartner.emit( org ); console.log(4);">{{ org.Name }}</button>
        </ng-template>
      </mat-menu>

      <div class="border-identifier" [style.backgroundColor]="item.Color ? item.Color : undefined"></div>
    </mat-card>

    <div class="control" (click)="toggleChild(childControl)" *ngIf="item?.PrimChildren?.length ||item?.PrimParentRoles?.length">
      <div class="icon">
        <mat-icon>{{ childControl.hide ? 'add' : 'remove' }}</mat-icon>
      </div>
      <span>{{ childControl.hide ? 'Show' : 'Hide' }}</span>
    </div>

    <div *ngIf="!childControl.hide && (item?.PrimChildren?.length ||item?.PrimParentRoles?.length )" class="treeconnection-bottom"></div>
  </div>
</ng-template>

<ng-template #ddcard let-item="item" let-list="list" let-mode="mode" let-parent="parent" let-partner="partner">
  <div [class.inList]="mode!='horizontal'" class="draggable" cdkDropList [cdkDropListData]="item"
    (cdkDropEntered)="entered($event)" (cdkDropListDropped)="drop($event)"
    [cdkDropListDisabled]="partner || isSameOrDescendant(item)" [class.partner]="partner">
    <mat-card appearance="outlined" class="table-card" cdkDrag [cdkDragData]="{ item: item, parent: parent }"
      (cdkDragStarted)="cdkDragStarted( $event, item );" (cdkDragReleased)="cdkDragReleased( $event )">
      <!-- (cdkDragEnded)="onDragEnded( $event )" -->
      <mat-card-title>{{item.Name}}</mat-card-title>
      <mat-card-subtitle>{{item?.Tag}}</mat-card-subtitle>

      <div class="actions">
        <span></span> <!-- flex -->
        <span [class.disabled]="permissions.viewDisabled" (click)="toggleInfo( moreinfo, extradataEl, item );"
          #moreinfo>
          {{ moreinfo.expanded ? 'Hide' : 'View'}} permissions
          <mat-icon *ngIf="!moreinfo.expanded">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="moreinfo.expanded">keyboard_arrow_up</mat-icon>
        </span>
        <span [class.disabled]="permissions.manageDisabled" (click)="permissionFor.emit( item )">
          <mat-icon>settings</mat-icon>
        </span>
        <span class="divider"></span>
        <span (click)="showExtraData( extradataEl, moreinfo, item.Organization.OID )" #extradataEl>More</span>
      </div>

      <div *ngIf="!permissions.viewDisabled && moreinfo.expanded" class="more-info">
        <div>
          <div class="partners">Partners:</div>
          <ng-container *ngFor="let partnerItem of item.ViewPermisionsPartner;">
            <div>{{partnerItem.Name}}</div>
          </ng-container>
        </div>
        <div>
          <div class="customers">Customers:</div>
          <ng-container *ngFor="let customerItem of item.ViewPermisionsCustomer;">
            <div>{{customerItem.Name}}</div>
          </ng-container>

        </div>
      </div>

      <div *ngIf="extradataEl.expanded" class="extra-data">
        <ng-container
          *ngTemplateOutlet="extraDataContainer;context:{ extradataEl: extradataEl, item: item }"></ng-container>
      </div>

      <button mat-icon-button [matMenuTriggerFor]="menu" matTooltip="options" class="option-button">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu>
        <button mat-menu-item (click)="editBranch( item )">View from here</button>
        <button mat-menu-item (click)="remove.emit( item )">Remove</button>
        <!-- [matMenuTriggerData]="{orgType: item.type}" -->
        <button mat-menu-item [matMenuTriggerFor]="customMenu">Add child</button>
      </mat-menu>

      <mat-menu #customMenu="matMenu">
        <ng-template matMenuContent> <!-- let-action="orgType" -->
          <button mat-menu-item (click)="newOrganization.emit( { item: item } );test();">+ Add new</button>
          <button mat-menu-item *ngFor="let org of availableOrganizations;" (click)="addChild( org, item );">{{ org.Name
            }}</button>
        </ng-template>
      </mat-menu>

      <div class="border-identifier"
        [style.backgroundColor]="partner ? 'rgb(255, 160, 0)' : item.Color ? item.Color : undefined"></div>
      <div class="drag-placeholder" *cdkDragPlaceholder></div>
    </mat-card>
    <mat-card appearance="outlined" class="table-card" *ngIf="isDragged(item)">
      <mat-card-title>{{item.Name}}</mat-card-title>
      <mat-card-subtitle>{{item.Tag}}</mat-card-subtitle>

      <div class="actions">
        <span></span> <!-- flex -->
        <span [class.disabled]="permissions.manageDisabled">Manage permissions</span>
        <span class="divider"></span>
        <span [class.disabled]="permissions.viewDisabled" #moreinfo>
          {{ moreinfo.expanded ? 'Hide' : 'View'}} permissions
          <mat-icon *ngIf="!moreinfo.expanded">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="moreinfo.expanded">keyboard_arrow_up</mat-icon>
        </span>
      </div>

      <div class="border-identifier" [style.backgroundColor]="item.Color ? item.Color : undefined"></div>
    </mat-card>
  </div>
</ng-template>

<ng-template #list let-item="item">
  <ul>
    <li *ngFor="let child of item.PrimChildren">
      <div class="content-left">
        <div class="treeconnection-left"></div>
        <div class="treeunion-left"></div>
        <div class="treedot-left"></div>

        <ng-container *ngTemplateOutlet="ddcard;context:{item: child, list: item.PrimChildren, parent: item}"></ng-container>

        <ng-container *ngIf="child.PrimChildren.length">
          <div #childControl class="control" (click)="toggleChild(childControl)"
            [ngStyle]="{ width: '80px', paddingLeft: '15px', 'marginLeft': 0 }">
            <div class="icon">
              <mat-icon>{{ childControl.hide ? 'add' : 'remove' }}</mat-icon>
            </div>
            <span>{{ childControl.hide ? 'Show' : 'Hide' }}</span>
          </div>

          <ng-container *ngIf="!childControl.hide">
            <ng-container *ngTemplateOutlet="list;context:{item: child}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </li>
  </ul>
</ng-template>

<ng-template #firstLevel let-item="item">
  <div class="child-container">
    <table class="collapse-table">
      <tr class="graph-list" [class.empty]="!item?.PrimChildren?.length">
        <td *ngFor="let child of item.PrimChildren; index as i;" class="firstLevel"
          [class.firstConnectionFix]="item.PrimChildren.length > 1 && i === 0">
          <div class="treeunion-fix" *ngIf="item.PrimChildren.length > 1 && item.PrimChildren.length > i+1" [class.first]="i === 0">
          </div>
          <div class="content">
            <div class="treeunion" *ngIf="item.PrimChildren.length > 1 && (i == 0 || item.PrimChildren.length == i+1)"
              [class.treelastchild]="item.PrimChildren.length === i+1"></div>
            <div class="treeconnection-top"></div>
            <div class="treedot"></div>

            <ng-container
              *ngTemplateOutlet="ddcard;context:{item: child, list: item.PrimChildren, mode: 'horizontal', parent: item}"></ng-container>

          </div>
          <div *ngIf="item.PrimChildren.length > 1 && i === 0" class="treeconnection-top-fix"></div>
        </td>
      </tr>
      <tr>
        <td class="children-list" [class.emptyChild]="!child.PrimChildren.length" *ngFor="let child of item.PrimChildren">
          <ng-container *ngIf="child.PrimChildren.length">
            <div class="control" (click)="toggleChild(childControl)" #childControl
              [ngStyle]="{ width: '80px', paddingLeft: '15px', 'marginLeft': 0 }">
              <div class="icon">
                <mat-icon>{{ childControl.hide ? 'add' : 'remove' }}</mat-icon>
              </div>
              <span>{{ childControl.hide ? 'Show' : 'Hide' }}</span>
            </div>
            <ng-container *ngIf="!childControl.hide">
              <ng-container *ngTemplateOutlet="list;context:{item: child}"></ng-container>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</ng-template>

<ng-template #firstLevelRelation let-item="item">
  <div class="child-container">
    <table class="collapse-table">
      <tr class="graph-list" [class.empty]="!item.PrimChildren.length">
        <td *ngFor="let child of item.PrimChildren; index as i;" class="firstLevel"
          [class.firstConnectionFix]="item.PrimChildren.length > 1 && i === 0">
          <div class="treeunion-fix" *ngIf="item.PrimChildren.length > 1 && item.PrimChildren.length > i+1" [class.first]="i === 0">
          </div>
          <div class="content">
            <div class="treeunion" *ngIf="item.PrimChildren.length > 1 && (i == 0 || item.PrimChildren.length == i+1)"
              [class.treelastchild]="item.PrimChildren.length === i+1"></div>
            <div class="treeconnection-top"></div>
            <div class="treedot"></div>

            <ng-container
              *ngTemplateOutlet="ddcard;context:{item: child, list: item.PrimChildren, mode: 'horizontal', parent: item}"></ng-container>

          </div>
          <div *ngIf="item.PrimChildren.length > 1 && i === 0" class="treeconnection-top-fix"></div>
        </td>
      </tr>
      <tr>
        <td class="children-list" [class.emptyChild]="!child.PrimChildren.length" *ngFor="let child of item.PrimChildren">
          <ng-container *ngIf="child.PrimChildren.length">
            <div class="control" (click)="toggleChild(childControl)" #childControl
              [ngStyle]="{ width: '80px', paddingLeft: '15px', 'marginLeft': 0 }">
              <div class="icon">
                <mat-icon>{{ childControl.hide ? 'add' : 'remove' }}</mat-icon>
              </div>
              <span>{{ childControl.hide ? 'Show' : 'Hide' }}</span>
            </div>
            <ng-container *ngIf="!childControl.hide">
              <ng-container *ngTemplateOutlet="list;context:{item: child}"></ng-container>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </table>
  </div>
</ng-template>
<ng-template #node let-item="item">
  <table class="graph-table collapse-table">
    <tbody>
      <tr class="graph-list"> <!-- subscriber -->
        <td [attr.colspan]="item.PrimChildren.length" #childControl>
          <div [class.partnersline]="hasPartners( item )"></div>
          <div [class]="'main-card-container' + (item.PrimChildren.length <= 1 ? '-fix' : '')">
            <ng-container *ngTemplateOutlet="mainCard;context:{item: item, childControl: childControl}"></ng-container>
          </div>
        </td>
      </tr>
      <tr *ngIf="!childControl.hide && item.PrimChildren.length">
        <td class="children" [class.emptyChild]="!item.PrimChildren.length">
          <ng-container *ngTemplateOutlet="firstLevelRelation;context:{item: item}"></ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="hasPartners( item )" class="partners" [ngStyle]="{paddingLeft: 0, alignItems: 'start'}">
    <ul>
      <li *ngFor="let partner of item.Partners; index as pi">
        <div class="content-left">
          <div class="treeconnection-left"></div>
          <div class="treeunion-left"></div>
          <div class="treedot-left"></div>

          <ng-container *ngTemplateOutlet="ddcard;context:{item: partner, parent: item, partner: true}"></ng-container>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #nodeRelation let-item="item">
  <table class="graph-table collapse-table">
    <tbody> 
      <tr *ngIf="item.PrimChildren.length">
        <td class="children" [class.emptyChild]="!item.PrimChildren.length">
          <ng-container *ngTemplateOutlet="list;context:{item: item}"></ng-container>
        </td>
      </tr>
    </tbody>
  </table>

  <div *ngIf="hasPartners( item )" class="partners" [ngStyle]="{paddingLeft: 0, alignItems: 'start'}">
    <ul>
      <li *ngFor="let partner of item.Partners; index as pi">
        <div class="content-left">
          <div class="treeconnection-left"></div>
          <div class="treeunion-left"></div>
          <div class="treedot-left"></div>

          <ng-container *ngTemplateOutlet="ddcard;context:{item: partner, parent: item, partner: true}"></ng-container>
        </div>
      </li>
    </ul>
  </div>
</ng-template>