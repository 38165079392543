// import { Component } from '@angular/core';
// import { StructuresService, StructuresServiceConfiguration } from '@smartobjx/smart.connectors';

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css'],
//   providers: [
//     StructuresService,
//     {
//       provide: StructuresServiceConfiguration,
//       useValue: new StructuresServiceConfiguration({
//         basePath: 'http://localhost/SmartStructures'
//       })
//     }]
// })
// export class AppComponent {
//   title = 'app';
// }

import { Component, forwardRef } from '@angular/core';
import { AccessService, AccessServiceConfiguration, MarketService, MarketServiceConfiguration, SettingsService, SettingsServiceConfiguration, StructuresService, StructuresServiceConfiguration } from '@smartobjx/smart.connectors';
import { AuthService } from './core-services/authentication/auth.service';
import { combined } from './core-services/mediator/combined';
import { structuresConfigurationService } from './core-services/structuresConfigurationService';
import { Router, NavigationEnd } from '@angular/router';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { marketConfigurationService } from './core-services/marketConfigurationService';
import { settingsConfigurationService } from './core-services/settingsConfigurationService';
import { accessConfigurationService } from './core-services/accessConfigurationService';

export function StructuresFactoryService() {
  return (_structuresServiceConfiguration: StructuresServiceConfiguration): StructuresService => {
    return new StructuresService(_structuresServiceConfiguration);
  };
}
export function MarketFactoryService() {
  return (_marketServiceConfiguration: MarketServiceConfiguration): MarketService => {
    return new MarketService(_marketServiceConfiguration);
  };
}
export function SettingsFactoryService() {
  return (_settingsServiceConfiguration: SettingsServiceConfiguration): SettingsService => {
    return new SettingsService(_settingsServiceConfiguration);
  };
}
export function AccessFactoryService() {
  return (_accessServiceConfiguration: AccessServiceConfiguration): AccessService => {
    return new AccessService(_accessServiceConfiguration);
  };
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    StructuresService,
    MarketService,
    SettingsService,
    AccessService,
    {
      provide: StructuresService,
      useFactory: StructuresFactoryService(),
      deps: [StructuresServiceConfiguration]
    },
    {
      provide: StructuresServiceConfiguration,
      useFactory: structuresConfigurationService(),
      deps: [AuthService]
    },


    {
      provide: MarketServiceConfiguration,
      useFactory: marketConfigurationService(),
      deps: [AuthService]
    },
    {
      provide: MarketService,
      useFactory: MarketFactoryService(),
      deps: [MarketServiceConfiguration]
    },
    {
      provide: SettingsServiceConfiguration,
      useFactory: settingsConfigurationService(),
      deps: [AuthService]
    },
    {
      provide: SettingsService,
      useFactory: SettingsFactoryService(),
      deps: [SettingsServiceConfiguration]


    },

    {
      provide: AccessServiceConfiguration,
      useFactory: accessConfigurationService(),
      deps: [AuthService]
    },
    {
      provide: AccessService,
      useFactory: AccessFactoryService(),
      deps: [AccessServiceConfiguration]
    },
    ...combined(),


  ]
})
export class AppComponent {
  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authSvc: AuthService) {
    this.registerSvgIcon("smart_objx_colorblack", "smartObjx-logo_smartObjx-colorblack-RGB");
    this.registerSvgIcon("smart_objx_colorwhite", "smartObjx-logo_smartObjx-colorwhite-RGB");
    this.registerSvgIcon("smart_objx_black", "smartObjx-logo_smartObjx-black-RGB");
    this.registerSvgIcon("smart_objx_white", "smartObjx-logo_smartObjx-white-RGB");
    this.registerSvgIcon("use_cases_empty", "use-cases-empty");
    this.registerSvgIcon("dead_end", "dead-end");
    this.registerSvgIcon("upload_arrow", "upload-arrow");
    console.log("test deploy 11072022")
    // window.addEventListener('povchanged', e => {
    //     this.checkUser();
    // });
  }
  private registerSvgIcon(name: string, assetName: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/${assetName}.svg`));
  }

  // private checkUser(){
  //     const auth = this.authSvc as any;
  //     this.user = auth.getUser();
  // }

  get user(): string {
    const auth = this.authSvc as any;
    return auth.getUser();
  }

  logout() {
    this.router.navigate(['/logout']);
    this.authSvc.userName = null;
  }

  // getShowMenu(): boolean {
  //     return !!this.user;
  // }
  title: string = 'ACCESS';
}

