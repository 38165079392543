<div class="h6 add-element title" matDialogTitle>
    <div class="text">Create new organization</div>
</div>
<mat-dialog-content class="add-element content">
    <!-- <ng-container *ngIf="priorToParent; else priorToParentMessageForStartDate">
        Choose a name for your new client.
    </ng-container>
    <ng-template #priorToParentMessageForStartDate>
        The chosen Start date has already passed. <br />
        Do you want to make these changes active immediately?
    </ng-template> -->
    <span class="main-info">Choose a name for your new organization.</span>
    <mat-form-field (keyup)="checkCustomValidation()">
        <input matInput
            [(ngModel)]="organization.Name"
            placeholder="Organization's name"
        />
        <mat-hint>This field cannot be empty</mat-hint>
    </mat-form-field>
    <mat-hint *ngIf="!customValidationResult && customValidationMessage" class="error">{{ customValidationMessage }}</mat-hint>
    <span class="link" #moreinfo (click)="moreinfo.expanded=!moreinfo.expanded">
        {{ moreinfo.expanded ? 'Less' : 'More'}} options 
        <mat-icon *ngIf="!moreinfo.expanded">keyboard_arrow_down</mat-icon>
        <mat-icon *ngIf="moreinfo.expanded">keyboard_arrow_up</mat-icon>
    </span>
    <ng-container *ngIf="moreinfo.expanded">
        <span class="optional-info">All this information is optional.</span>
        <mat-form-field>
            <input matInput
                [(ngModel)]="organization.email"
                placeholder="E-mail"
            />
        </mat-form-field>
        <mat-form-field>
            <input matInput
                [(ngModel)]="organization.phone"
                placeholder="Phone number"
            />
        </mat-form-field>
        <mat-form-field>
            <input matInput
                [(ngModel)]="organization.industry"
                placeholder="Industry"
            />
        </mat-form-field>
    </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button matDialogClose>Close</button>
    <button mat-raised-button matDialogClose color="primary" [disabled]="isNotValid" (click)="save()">{{ showUpdate ? 'Update' : 'Create' }}</button>
</mat-dialog-actions>

<!-- <h2 mat-dialog-title>{{title}}</h2>


<mat-dialog-content [formGroup]="form">

    <mat-form-field>

        <input matInput
                placeholder="Course Description"
               formControlName="description">

    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions>

    <button class="mat-raised-button"
            (click)="close()">
        Close
    </button>

    <button class="mat-raised-button mat-primary"
            (click)="save()">
        Save
    </button>

</mat-dialog-actions> -->