import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { Tools } from '../shared/Tools';

@Component( {
  selector: 'custom-date',
  templateUrl: './custom-date.component.html',
  styleUrls: [ './custom-date.component.scss' ]
} )

export class CustomDateComponent implements OnInit
{
  // #region Event Handlers
  // #endregion
  
  // #region Private Methods
  simpleDate( date: any ){
    return Tools.dateToString( date );
  } 
  private pad(n: number, len: number = 2){
    return Tools.padZero(n + '', len);
  }
  private setValid(){
    this.forceInvalidChange.emit(false);
    const children = this.containerRef.nativeElement.children;
    for(const i in children) {
      const list = children[i].classList;
      if(list && list.contains(this.invalidClassName))
        list.remove(this.invalidClassName);
    }
  }
  setInvalid(){
    const children = this.containerRef.nativeElement.children;
    for(const i in children) {
      const list = children[i].classList;
      if(list && !list.contains(this.invalidClassName))
        list.add(this.invalidClassName);
    }
  }
  setFocused(){
    const children = this.containerRef.nativeElement.children;
    for(const i in children) {
      const list = children[i].classList;
      if(list && !list.contains(this.focusedClassName))
      {
        children[i].children[1].classList.add(this.rippleActiveClassName);
        children[i].children[1].classList.add(this.rippleLineClassName);
        list.add(this.focusedClassName);
      }
    }
  }
  removeFocused(){
    const children = this.containerRef.nativeElement.children;
    for(const i in children) {
      const list = children[i].classList;
      if(list && list.contains(this.focusedClassName))
        children[i].children[1].classList.remove(this.rippleActiveClassName);
        children[i].children[1].classList.remove(this.rippleLineClassName);
        list.remove(this.focusedClassName);
    }
  }
  private setNativeValue(ref: any, value: string, len: number = 2){
    ref.nativeElement.value = Tools.padZero(value, len);
  }
  // #endregion

  // #region Construction & Finalization
  constructor () 
  { }

  ngOnInit(){
    this.prevHour = this.model.getHours();
  }
  
  ngOnChanges(changes: any): void {
    if(changes.forceInvalid && !changes.forceInvalid.firstChange && changes.forceInvalid.currentValue){
      this.setInvalid();
    }
  }
  // #endregion

  // #region Properties
  @Input() model: Date;
  @Input() forceInvalid: boolean = false;
  @Input() placeholder: string = 'Start date';
  @Output() forceInvalidChange = new EventEmitter();
  
  @ViewChild('container', {static: false}) containerRef: any;
  @ViewChild('month', {static: false}) monthRef: any;
  @ViewChild('day', {static: false}) dayRef: any;
  @ViewChild('year', {static: false}) yearRef: any;
  @ViewChild('hours', {static: false}) hoursRef: any;
  @ViewChild('minutes', {static: false}) minutesRef: any;
  prevHour;
  invalidClassName = 'mat-form-field-invalid';
  focusedClassName = 'mat-focused';
  rippleActiveClassName = 'mdc-line-ripple--active'
  rippleLineClassName = 'mdc-line-ripple'

  get dateMonth(): any {
    return this.pad(this.model.getMonth() + 1);
  }
  set dateMonth( value ) {
    //#region validations
    const max = 12;

    if (value < 0){
      value = Math.abs(value);
      this.setNativeValue(this.monthRef, value);
    }

    if(value >= max + (this.arrowPressed ? 2 : 1)){
      const s = `${value}`;
      value = parseInt( (s).slice(0, s.length-1) );
      this.setNativeValue(this.monthRef, value);
    } else {
      if(value === max+1){
        this.setNativeValue(this.monthRef, '1');
      }
    }

    if((value === null || value === 0) && (!this.arrowPressed || this.model.getMonth() !== 0)){
      return;
    }
    //#endregion

    this.model.setMonth( value - 1 );
    this.setValid();
  }
  get dateDay(): any {
    return this.pad(this.model.getDate());
  }
  set dateDay( value ) {
    //#region validations
    const month = this.model.getMonth() + 1;
    const year = this.model.getFullYear();
    const max = Tools.getDaysInMonth(month, year);

    if (value < 0){
      value = Math.abs(value);
      this.setNativeValue(this.dayRef, value);
    }

    if(value >= max + (this.arrowPressed ? 2 : 1)){
      const s = `${value}`;
      value = parseInt( (s).slice(0, s.length-1) );
      this.setNativeValue(this.dayRef, value);
    }
    
    if(value === max+1){
      this.setNativeValue(this.dayRef, '1');
    }
    
    if((value === null || value === 0) &&  (!this.arrowPressed || this.model.getDate() !== 1)){
      return;
    }
    //#endregion
    
    this.model.setDate( value );
    this.setValid();
  }
  get dateYear(): any {
    return this.pad(this.model.getFullYear(), 4);
  }
  set dateYear( value ) {
    if(value > 9999){
      const s = `${value}`;
      value = parseInt( (s).slice(0, s.length-1) );
      this.setNativeValue(this.yearRef, value, 4);
    }
    this.model.setFullYear( value );
    this.setValid();
  }
  get dateHour(): any {
    let hours = this.model.getHours();
    if(this.twelveHours == 'PM' && hours > 12){
      hours-=12;
    }
    if(hours === 0) hours = 12;
    return this.pad(hours);
  }
  set dateHour( hours ) {
    const max = 12;
    let value = hours;
    if (value < 0){
      value = Math.abs(value);
      this.setNativeValue(this.hoursRef, value);
    }

    if(value >= max + (this.arrowPressed ? 2 : 1)){
      const s = `${value}`;
      value = parseInt( (s).slice(0, s.length-1) );
      this.setNativeValue(this.hoursRef, value);
    }
    
    if(value === max+1){
      this.setNativeValue(this.hoursRef, '1');
    }
    
    if((value === null || value === 0) && (!this.arrowPressed || (this.model.getHours() !== 1 && this.model.getHours() !== 13))){
      return;
    }
    hours = value;

    if(this.twelveHours == 'PM'){
      if( hours === 11 && this.prevHour === 12 ){
        hours=11;
      } else if( hours === 12 && this.prevHour === 23 ){
        hours=24;
      } else if( hours < 12 )
        hours+=12;
    } else {
      if( hours === 11 && this.prevHour === 0 ){
        hours=-1;
      } else if( hours === 12 && this.prevHour === 11 ){
        hours=12;
      } else if ( hours >= 12 )
        hours-=12;
    }

    this.model.setHours( hours );
    this.prevHour = hours;
    this.setValid();
  }
  get dateMinute(): any {
    return this.pad(this.model.getMinutes());
  }
  set dateMinute( value ) {
    //#region validations
    const max = 59;

    if (value < -1){
      value = Math.abs(value);
      this.setNativeValue(this.minutesRef, value);
    }

    if(value >= max + (this.arrowPressed ? 2 : 1)){
      const s = `${value}`;
      value = parseInt( (s).slice(0, s.length-1) );
      this.setNativeValue(this.minutesRef, value);
    }
    
    if(value === max+1){
      this.setNativeValue(this.minutesRef, '1');
    }
    
    if((value === null || value === -1) && (!this.arrowPressed || this.model.getMinutes() !== 0)){
      return;
    }
    //#endregion
    this.model.setMinutes( value );
    this.setValid();
  }
  get dateForPicker(): Date {
    return this.model;
  }
  set dateForPicker( value ) {
    this.model.setMonth( value.getMonth() );
    this.model.setDate( value.getDate() );
    this.model.setFullYear( value.getFullYear() );
    this.model.setHours( value.getHours() );
    this.model.setMinutes( value.getMinutes() );
    this.model.setSeconds( 0 );
    this.model.setMilliseconds( 0 );
    this.setValid();
  }
  get twelveHours() {
    const time = this.model.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const ampm = time.split(' ')[1];
    return ampm;
  }
  onKeyUpAMPM( e: any ) {
    if(e.key === 'ArrowUp' || e.key === 'ArrowDown'){
      switch(this.twelveHours){
        case 'AM': this.model.setHours( this.model.getHours() + 12 ); break;
        case 'PM': this.model.setHours( this.model.getHours() - 12 ); break;
      }
    }
  }
  onKeyDownAMPM( e: any ){
    if(e.key !== 'Tab'){
      e.preventDefault();
    }
  }
  onClickAMPM( e: any ){
    e.target.select();
  }
  arrowPressed: boolean = false;
  keyPressed( e: any){
    if(e.key == "ArrowUp" || e.key == "ArrowDown"){
      this.arrowPressed = true;
    }
  }
  keyReleased( e: any ){
    if(e.key == "ArrowUp" || e.key == "ArrowDown"){
      this.arrowPressed = false;
    }
  }
  // set twelveHours( value ){
  //   // console.log(value);
  //   if(this.twelveHours != value){
  //     switch(value){
  //       case 'AM': this.model.setHours( this.model.getHours() - 12 ); break;
  //       case 'PM': this.model.setHours( this.model.getHours() + 12 ); break;
  //     }
  //   }
  // }
  // #endregion

  // #region Event Emitters
  // #endregion
}