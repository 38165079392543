import { BrowserModule, EventManager } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { StructuresService } from '@smartobjx/smart.connectors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StructuresAdmComponent } from './access-adm/access-adm.component';
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';

//#region material
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
//#endregion

import { AppComponent } from './app.component';
import { StructuresAdmModule } from './access-adm/access-adm.module';

import { AuthGuardService } from './core-services/authentication/guards/auth-guard.service';
import { LoginPortalComponent } from './login-portal/login-portal.component';
import { LogoutComponent } from './login-portal/logout.component';
import { CustomEventManager } from './shared/custom-event-manager';
import { AuthInterceptorService } from './core-services/interceptor.service';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RegisterSubscriptionComponent } from './register-subscription/register-subscription.component';

const appRoutes: Routes = [
    {
        path: '',
        component: StructuresAdmComponent,
        pathMatch: 'full',
        canActivate: [AuthGuardService],
    },
    {
        path: 'login',
        component: LoginPortalComponent,
        pathMatch: 'full',
    },
    {
        path: 'logout',
        component: LogoutComponent,
    },
    {
        path: 'registerSubscription',
        component: RegisterSubscriptionComponent,
        pathMatch: 'full',
    },
    { path: '**', redirectTo: '/' }
]

@NgModule({
    declarations: [
        AppComponent,
        LoginPortalComponent,
        LogoutComponent,
        RegisterSubscriptionComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatIconModule,
        MatTreeModule,
        MatToolbarModule,
        MatDividerModule,
        MatSelectModule,
        MatMenuModule,
        MatProgressBarModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        StructuresAdmModule,
        RouterModule.forRoot(appRoutes, {})
    ],
    providers: [
        { provide: EventManager, useClass: CustomEventManager },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }