import { MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { environment } from '../../environments/environment';
import { AuthService } from './authentication/auth.service';

export function marketConfigurationService() {
  return (_authenticationService: AuthService): MarketServiceConfiguration => {
    return new MarketServiceConfiguration({
      httpClient: _authenticationService.http,
      basePath: environment.marketServiceBasePath,
      SubscriberToken: _authenticationService.getTokenId(),
      POVToken: _authenticationService.getPOV()
    });
  };
}