import { Component, OnInit, forwardRef } from '@angular/core';
import { MarketService, MarketServiceConfiguration } from '@smartobjx/smart.connectors';
import { Subscription } from '@smartobjx/smart.objx.models';
import { AuthService } from '../core-services/authentication/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import Mediator from '../core-services/mediator/login-portal.mediator';
import { environment } from '../../environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
let serviceConfig: MarketServiceConfiguration = new MarketServiceConfiguration({
  basePath: 'http://localhost/smart.Market.Service'
});

@Component({
  selector: 'register-subscription',
  templateUrl: './register-subscription.component.html',
  styleUrls: ['./register-subscription.component.scss']
})
export class RegisterSubscriptionComponent implements OnInit {

  private pov: string;
  private _token: string;
  private keep: boolean;
  selectedSubscription: any;
  dataOwners: any;
  demoSubscriptions: Subscription[];
  isIframe = false;
  loggedIn = false;
  form: UntypedFormGroup;
  offerMarket: any;
  successRegister: boolean = false;
  isLoading: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private mediator: Mediator,
    private route: ActivatedRoute,
    private _router: Router) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        if (!_.isNil(params) && !_.isNil(params.offerMarket)) {
          this.offerMarket = params.offerMarket
        }
      })

    this.form = this.fb.group({
      name: ["", Validators.required]

    })


  }

  register() {
    this.isLoading = true
    this.mediator.ActiveMarketPlaceOffer(this.offerMarket, this.form.value.name).subscribe((x: any) => {
      if (x.AuthTokens.length > 0) {
        this.successRegister = true
        setTimeout(() => {
          this._router.navigate(['/login'])
        }, 8000); 
      }
    })
    console.log(this.form.value.name)

  }
}