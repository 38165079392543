import { Component, EventEmitter, Input, Output, OnInit, HostListener } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UseCase, AbstractRule, SecurityApplication } from '@smartobjx/smart.objx.models';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { CustomValidator } from '../shared/Validation';
import Mediator from '../core-services/mediator/access.mediator';

@Component({
  selector: 'card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})

export class CardListComponent implements OnInit {
  // #region Event Handlers
  toShortDate(date: any) {
    return Tools.toShortDate(date);
  }
  isNotRepleaceable(r: AbstractRule): boolean {
    return r.NotReplaceable && r.OwnerID != this.pov;
  }
  getIconByType = (record: any) => Tools.getIconByType(record);


  onChangeSelectedDate(apps: SecurityApplication, date: Date) {
    this.onVersions.emit({ record: apps, date, versionDates: this.versionDates });
  }
  getVersions(configId: string) {
    this.versionsIsLoading = true;
  // this.mediator.FindConfigVersions(configId)
  //   .subscribe((data: any) => {
  //     this.versionsIsLoading = false;
  //     this.versionDates = data.map((o: any) => CustomValidator.ensureDate(o.Version));
  //     if (this.versionDates.length) {
  //       this.firstVersionsDate = this.versionDates[0];
  //     }
  //   });
  }
  recordIsDisabled(record: any) {
    if (typeof this.forceRuleStatus === 'undefined' || !this.forceRuleStatus.length) {
      return record.Disabled;
    }
    let found = this.forceRuleStatus.filter(o => o.rule === record);
    if (found.length) {
      return found[0].disable;
    }
    return record.Disabled;
  }
  formatName(name: string) {
    if (this.highlightWords.length === 0) return name;

    let markedChars = [];
    for (let wi in this.highlightWords) {
      const word = this.highlightWords[wi];
      let re = new RegExp(word, 'g');
      let result = null;
      while (result = re.exec(name.toLowerCase())) {
        for (let i = 0; i < word.length; i++) {
          let newIndex = result.index + i;
          if (!~markedChars.indexOf(newIndex))
            markedChars.push(newIndex);
        }
      }
    }

    if (markedChars.length === 0) return name;

    let ranges = [];
    const list = markedChars.sort((a, b) => a - b);
    let to = list[list.length - 1], from = to;
    if (list.length > 1) {
      for (let i = list.length - 2; i >= 0; i--) {
        if (list[i] === from - 1) {
          from = list[i];
        } else {
          ranges.push([from, to]);
          to = from = list[i];
        }
      }
    }
    ranges.push([from, to]);

    let listName = name.split('');
    ranges.forEach(range => {
      listName.splice(range[1] + 1, 0, '</b>');
      listName.splice(range[0], 0, '<b class="search">');
    });
    return listName.join('');
  }
  // #endregion

  // #region Private Methods
  // #endregion

  // #region Construction & Finalization
  constructor(private mediator: Mediator, private _authService: AuthService,
  ) { }

  ngOnInit() {
    if (this.versionDates.length) {
      this.firstVersionsDate = this.versionDates[0];
    }
    this.innerWidth = window.innerWidth;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);
    this.onChanges.emit();
  }

  @HostListener('window:resize', ['$event'])
  onResize(e: any) {
    this.innerWidth = window.innerWidth;
  }
  // #endregion

  // #region Properties
  @Input() model: any[];
  get modelSorted(): any[] {
    if (!this.sortBy) {
      return this.model;
    }
    const prop = this.sortBy;
    return this.model.sort((a, b) => {
      let al = a[prop].toLowerCase(), bl = b[prop].toLowerCase();
      return (
        al > bl ? 1
          : al < bl ? -1
            : 0
      );
    });
  }
  @Input() height: string = "";
  @Input() overflow: string = "";
  @Input() nameFontSize: string = "";
  @Input() showDeleteAllRelationship: boolean = false;
  @Input() sortBy: string = "";
  @Input() forceRuleStatus: any[]; // { rule: rule, disable: disable }
  @Input() showType: boolean = true;
  @Input() showEdit: boolean = true;
  @Input() pov: string = "";
  @Input() showDebug: boolean;
  @Input() showVersions: boolean;
  @Input() selectedIndex: number;
  @Input() showDelete: boolean = true;
  @Input() hideOptions: boolean;
  @Input() showSorter: boolean = false;
  @Input() canHover: boolean = true;
  @Input() sortAlphabetically: boolean = false;
  @Input() showVersionOn: (record: any) => void = o => false;
  @Input() highlightWords: string[] = [];
  @Input() showStats: boolean;
  @Input() showSelect: boolean;
  
  get showMobileOptions(): boolean {
    return this.showEdit || this.showDebug || this.showVersions || this.showDelete;
  }
  getConfigId(record): string {
    return !_.isNil(record.ConfigId) ? record.ConfigId : record.OID
  }


  deleteTooltip(record: any): string {
    return record.Disabled ? 'Enable' : 'Disable';
  }
  selectedDate: Date = new Date();
  versionDates: Date[] = [];
  private firstVersionsDate: Date;
  private versionsIsLoading: boolean = false;
  public innerWidth: any;
  // #endregion

  // #region Event Emitters
  @Output() onEdit = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Output() onVersions = new EventEmitter<{ record: any, date: Date, versionDates: any[] }>();
  @Output() onDebug = new EventEmitter<any>();
  @Output() onChanges = new EventEmitter<any>();
  @Output() onStats = new EventEmitter<any>();
  @Output() selectApplication = new EventEmitter<any>();
  @Output() onDeleteAllRelationship = new EventEmitter<any>();

  
  
  // #endregion
}